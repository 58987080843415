<template>
  <div>
    <!-- promo-video -->
    <section class="fdn-promo-video">
      <div class="container">
        <div class="fdn-promo-video-content">
          <div class="fdn-promo-video-content-text">
            <h2>A ESCOLHA CERTA</h2>
            <p>Prático, leve, responsivo e preciso. Tenha nas mãos de todos seus colaboradores, o conhecimento necessário sobre seu negócio.</p>
            <div class="fdn-promo-video-content-text-action">
              <button
                type="button"
                class="btn btn-default"
              >
                <span data-play-promo-video>Assista o vídeo</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.329"
                  height="27.542"
                  viewBox="0 0 20.329 27.542"
                >
                  <path
                    id="play-button"
                    d="M25.546,13.231,6.528.115A.656.656,0,0,0,5.5.655V26.886a.656.656,0,0,0,1.028.54L25.546,14.31a.656.656,0,0,0,0-1.079Z"
                    transform="translate(-5.5)"
                    fill="#828282"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div class="fdn-promo-video-content-video" style="border-radius: 10px;overflow: hidden;">
            <iframe 
              style="border: none;min-height: 250px;min-width: 350px;width: 100%;height: 100%;" 
              src="https://player.vimeo.com/video/309343413?loop=false&amp;autoplay=false&amp;muted=false&amp;gesture=media&amp;playsinline=true&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=false&amp;customControls=true" allowfullscreen="" allow="autoplay; fullscreen; picture-in-picture; encrypted-media; accelerometer; gyroscope" title="Player for FastEad - Rápido para todas as empresas" data-ready="true" tabindex="-1">
            </iframe>
          </div>
        </div>
      </div>
    </section>

    <section class="fdn-features-plataforma">
      <div class="container">
        <div class="fdn-features-plataforma-header">
          <h2>Leve até o seus colaboradores ou alunos o poder da melhor plataforma EAD</h2>
        </div>
        <div class="fdn-features-plataforma-content">
          <img
            v-lazy="require('@/assets/images/features-plataforma.png')"
            alt="Benefícios da plataforma FastEAD para sua empresa"
          >
          <div class="fdn-features-plataforma-content-list-mobile">
            <div class="fdn-features-plataforma-content-list-mobile-item">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="104.971"
                height="105"
                viewBox="0 0 104.971 105"
              >
                <g
                  id="online_learnning"
                  data-name="online learnning"
                  transform="translate(-2 -1.983)"
                >
                  <path
                    id="Caminho_240"
                    data-name="Caminho 240"
                    d="M7.639,92.987H98.334a3.574,3.574,0,0,0,3.639-3.5V33.5A3.574,3.574,0,0,0,98.334,30H47.363a19.2,19.2,0,0,0,1.379-3.5h18.24a5.249,5.249,0,0,0,5.249-5.249v-3.5H96.724a5.249,5.249,0,0,0,5.249-5.249v-.322a5.249,5.249,0,1,0-3.5,0v.322a1.75,1.75,0,0,1-1.75,1.75H72.231V12.188a5.249,5.249,0,1,0-3.5,0v2.071H48.151A19.229,19.229,0,1,0,13.122,30H7.639A3.574,3.574,0,0,0,4,33.5V89.488A3.574,3.574,0,0,0,7.639,92.987ZM100.223,5.512a1.75,1.75,0,1,1-1.75,1.75A1.75,1.75,0,0,1,100.223,5.512Zm-29.742,0a1.75,1.75,0,1,1-1.75,1.75A1.75,1.75,0,0,1,70.482,5.512ZM18,68.494h-1.75v-1.75H18Zm.967-10.322A1.75,1.75,0,0,0,18,59.746v1.025l-1.75-1.75V58a8.758,8.758,0,0,1,8.748-8.748h10.5A8.758,8.758,0,0,1,44.239,58v1.025l-1.75,1.75V59.746a1.75,1.75,0,0,0-1.324-1.7l-14-3.5a1.736,1.736,0,0,0-1.207.131Zm23.526,8.573h1.75v1.75h-1.75ZM21.5,60.828,26.947,58.1,38.99,61.113v9.131a8.748,8.748,0,0,1-17.5,0ZM25.288,84.24H35.2l-.808,5.249H26.107Zm13.442.072a19.07,19.07,0,0,1,11.71,5.177h-12.5Zm-16.97.014.807,5.163h-12.5a19.01,19.01,0,0,1,11.69-5.163ZM68.732,17.758v3.5a1.75,1.75,0,0,1-1.75,1.75H49.4c.052-.577.089-1.158.089-1.75a19.226,19.226,0,0,0-.334-3.5ZM30.243,5.512A15.746,15.746,0,1,1,14.5,21.257,15.746,15.746,0,0,1,30.243,5.512ZM15.409,33.5a19.222,19.222,0,0,0,29.668,0h53.4l-.14,55.984H55.152a22.32,22.32,0,0,0-17.912-8.748h-.723a12.247,12.247,0,0,0,5.831-8.748h1.889a3.5,3.5,0,0,0,3.5-3.5v-1.75A3.5,3.5,0,0,0,44.9,63.312l2.329-2.329a1.75,1.75,0,0,0,.513-1.237V58A12.261,12.261,0,0,0,35.491,45.75h-10.5A12.261,12.261,0,0,0,12.748,58v1.75a1.749,1.749,0,0,0,.512,1.237l2.329,2.329a3.5,3.5,0,0,0-2.841,3.433v1.75a3.5,3.5,0,0,0,3.5,3.5h1.889a12.247,12.247,0,0,0,5.831,8.748h-.723A22.535,22.535,0,0,0,7.5,87.1l.14-53.6Z"
                    transform="translate(1.499)"
                    fill="#82c3e7"
                  />
                  <path
                    id="Caminho_241"
                    data-name="Caminho 241"
                    d="M39.249,70.986H67.241a5.249,5.249,0,0,0,5.249-5.249V27.249A5.249,5.249,0,0,0,67.241,22H39.249A5.249,5.249,0,0,0,34,27.249V65.738a5.249,5.249,0,0,0,5.249,5.249ZM37.5,27.249a1.75,1.75,0,0,1,1.75-1.75H67.241a1.75,1.75,0,0,1,1.75,1.75V65.738a1.75,1.75,0,0,1-1.75,1.75H39.249a1.75,1.75,0,0,1-1.75-1.75Z"
                    transform="translate(23.984 15.003)"
                    fill="#ffa24f"
                  />
                  <rect
                    id="Retângulo_20"
                    data-name="Retângulo 20"
                    width="24.493"
                    height="3.499"
                    transform="translate(64.982 47.5)"
                    fill="#ffa24f"
                  />
                  <rect
                    id="Retângulo_21"
                    data-name="Retângulo 21"
                    width="24.493"
                    height="3.499"
                    transform="translate(64.982 59.746)"
                    fill="#ffa24f"
                  />
                  <path
                    id="Caminho_262"
                    data-name="Caminho 262"
                    d="M0,0H24.493V3.5H0Z"
                    transform="translate(64.982 71.993)"
                    fill="#ffa24f"
                  />
                  <path
                    id="Caminho_242"
                    data-name="Caminho 242"
                    d="M105.221,56H61.483a1.75,1.75,0,0,0-1.75,1.75V59.5h-10.5V57.75A1.75,1.75,0,0,0,47.487,56H3.749a1.75,1.75,0,0,0-1.7,2.173l1.086,4.351A5.249,5.249,0,0,0,8.232,66.5h92.507a5.249,5.249,0,0,0,5.093-3.976l1.086-4.348a1.75,1.75,0,0,0-1.7-2.173ZM8.232,63a1.75,1.75,0,0,1-1.7-1.324L5.99,59.5H45.738v1.75A1.75,1.75,0,0,0,47.487,63Zm94.206-1.326a1.75,1.75,0,0,1-1.7,1.326H61.483a1.75,1.75,0,0,0,1.75-1.75V59.5H102.98Z"
                    transform="translate(0 40.486)"
                    fill="#ffa24f"
                  />
                  <path
                    id="Caminho_243"
                    data-name="Caminho 243"
                    d="M14.829,27.734a1.75,1.75,0,0,0,1.7.077l17.5-8.748a1.75,1.75,0,0,0,0-3.132l-17.5-8.748A1.75,1.75,0,0,0,14,8.75v17.5A1.75,1.75,0,0,0,14.829,27.734ZM17.5,11.581,29.333,17.5,17.5,23.414Z"
                    transform="translate(8.994 3.76)"
                    fill="#ffa24f"
                  />
                </g>
              </svg>
              <span>Cursos</span>
            </div>
            <div class="fdn-features-plataforma-content-list-mobile-item">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="102.198"
                height="74.889"
                viewBox="0 0 102.198 74.889"
              >
                <g
                  id="video-player"
                  transform="translate(0 -68.409)"
                >
                  <g
                    id="Grupo_17"
                    data-name="Grupo 17"
                    transform="translate(46.085 75.237)"
                  >
                    <g
                      id="Grupo_16"
                      data-name="Grupo 16"
                    >
                      <path
                        id="Caminho_279"
                        data-name="Caminho 279"
                        d="M276.214,148.3l-11.179-4.653a35.361,35.361,0,0,0,7.638-2.459,3.594,3.594,0,0,0,2.075-3.268c-.082-11.092-1.3-18.882-3.827-24.516-3.213-7.158-8.681-10.787-16.25-10.787s-13.037,3.629-16.25,10.787c-2.529,5.633-3.745,13.424-3.827,24.516a3.593,3.593,0,0,0,2.075,3.268,35.379,35.379,0,0,0,7.638,2.459L233.127,148.3a3.648,3.648,0,0,0,1.4,7.016h40.283a3.648,3.648,0,0,0,1.4-7.016Zm-38.191-10.012a.384.384,0,0,1-.23-.345c.078-10.633,1.205-18.014,3.547-23.229,2.688-5.987,7.049-8.9,13.331-8.9s10.643,2.91,13.331,8.9c2.341,5.215,3.468,12.6,3.547,23.229a.386.386,0,0,1-.23.346,33.745,33.745,0,0,1-8.448,2.514v-1.035l2.015-1.12a6.874,6.874,0,0,0,3.51-5.391l1.067-11.737a1.6,1.6,0,0,0-1.088-1.663,7.474,7.474,0,0,1-4.267-4.267,1.6,1.6,0,0,0-2.341-.866c-6.207,3.724-20.3,5.045-20.439,5.058a1.6,1.6,0,0,0-1.449,1.738l1.067,11.737a6.875,6.875,0,0,0,3.51,5.391l2.015,1.119V140.8A33.741,33.741,0,0,1,238.023,138.285Zm22.4,6.172a7.784,7.784,0,0,1-11.5,0,4.233,4.233,0,0,0,.749-2.408v-.507l1.658.921a6.84,6.84,0,0,0,6.682,0l1.658-.921v.508A4.23,4.23,0,0,0,260.419,144.458Zm-3.961-4.791a3.657,3.657,0,0,1-3.574,0l-6.873-3.819a3.676,3.676,0,0,1-1.877-2.883l-.925-10.169c3.685-.431,12.855-1.731,18.665-4.51a10.428,10.428,0,0,0,4.3,4.112l-.961,10.566a3.676,3.676,0,0,1-1.877,2.883Zm18.354,12.447H234.529a.448.448,0,0,1-.172-.861l11.919-4.961a10.981,10.981,0,0,0,16.79,0l11.919,4.961a.448.448,0,0,1-.172.861Z"
                        transform="translate(-230.881 -102.614)"
                        fill="#83c3e8"
                      />
                    </g>
                  </g>
                  <g
                    id="Grupo_19"
                    data-name="Grupo 19"
                    transform="translate(0 68.409)"
                  >
                    <g
                      id="Grupo_18"
                      data-name="Grupo 18"
                      transform="translate(0 0)"
                    >
                      <path
                        id="Caminho_280"
                        data-name="Caminho 280"
                        d="M93.771,68.409H8.428A8.437,8.437,0,0,0,0,76.837V134.87A8.437,8.437,0,0,0,8.428,143.3H93.771a8.437,8.437,0,0,0,8.428-8.428V76.837A8.437,8.437,0,0,0,93.771,68.409ZM99,134.87a5.233,5.233,0,0,1-5.227,5.227H8.428A5.233,5.233,0,0,1,3.2,134.87V76.837a5.233,5.233,0,0,1,5.227-5.227H93.771A5.233,5.233,0,0,1,99,76.837V134.87Z"
                        transform="translate(0 -68.409)"
                        fill="#ffa24f"
                      />
                    </g>
                  </g>
                  <g
                    id="Grupo_21"
                    data-name="Grupo 21"
                    transform="translate(8.534 128.15)"
                  >
                    <g
                      id="Grupo_20"
                      data-name="Grupo 20"
                    >
                      <path
                        id="Caminho_281"
                        data-name="Caminho 281"
                        d="M126.285,371.114H62.761a5.012,5.012,0,0,0-9.5,0h-8.9a1.6,1.6,0,1,0,0,3.2h8.9a5.012,5.012,0,0,0,9.5,0h63.524a1.6,1.6,0,0,0,0-3.2ZM58.01,374.527a1.816,1.816,0,0,1-1.813-1.808s0,0,0-.005,0,0,0-.005a1.814,1.814,0,1,1,1.813,1.819Z"
                        transform="translate(-42.755 -367.7)"
                        fill="#ffa24f"
                      />
                    </g>
                  </g>
                  <g
                    id="Grupo_23"
                    data-name="Grupo 23"
                    transform="translate(15.362 83.774)"
                  >
                    <g
                      id="Grupo_22"
                      data-name="Grupo 22"
                      transform="translate(0 0)"
                    >
                      <path
                        id="Caminho_282"
                        data-name="Caminho 282"
                        d="M93.483,147.647A10.131,10.131,0,1,0,81.274,163.8a1.73,1.73,0,0,1,.807,1.386v.568a3.311,3.311,0,0,0,3.307,3.307h.107v.107a1.6,1.6,0,0,0,3.2,0v-.107H88.8a3.311,3.311,0,0,0,3.307-3.307v-.577a1.632,1.632,0,0,1,.748-1.337,10.125,10.125,0,0,0,.626-16.2ZM91.036,161.21a4.852,4.852,0,0,0-2.128,3.968v.577a.107.107,0,0,1-.107.107H85.388a.107.107,0,0,1-.107-.107v-.568a4.882,4.882,0,0,0-2.168-4.005,6.931,6.931,0,0,1,2.468-12.443,7.472,7.472,0,0,1,1.529-.16,6.833,6.833,0,0,1,4.354,1.551,6.925,6.925,0,0,1-.429,11.08Z"
                        transform="translate(-76.96 -145.383)"
                        fill="#ffa24f"
                      />
                    </g>
                  </g>
                  <g
                    id="Grupo_25"
                    data-name="Grupo 25"
                    transform="translate(8.534 76.943)"
                  >
                    <g
                      id="Grupo_24"
                      data-name="Grupo 24"
                    >
                      <path
                        id="Caminho_283"
                        data-name="Caminho 283"
                        d="M81.573,140.959,76.68,132.8V117.886a6.728,6.728,0,0,0-6.721-6.721H49.477a6.728,6.728,0,0,0-6.721,6.721v25.6a6.728,6.728,0,0,0,6.721,6.721H69.959a6.728,6.728,0,0,0,6.721-6.721v-.107H80.2a1.6,1.6,0,0,0,1.372-2.424Zm-6.493-.777a1.6,1.6,0,0,0-1.6,1.6v1.707a3.524,3.524,0,0,1-3.52,3.52H49.477a3.524,3.524,0,0,1-3.52-3.52v-25.6a3.524,3.524,0,0,1,3.52-3.52H69.959a3.524,3.524,0,0,1,3.52,3.52v15.362a1.6,1.6,0,0,0,.228.823l3.666,6.111Z"
                        transform="translate(-42.756 -111.165)"
                        fill="#ffa24f"
                      />
                    </g>
                  </g>
                  <g
                    id="Grupo_27"
                    data-name="Grupo 27"
                    transform="translate(23.896 88.891)"
                  >
                    <g
                      id="Grupo_26"
                      data-name="Grupo 26"
                    >
                      <path
                        id="Caminho_284"
                        data-name="Caminho 284"
                        d="M121.316,171.023a1.6,1.6,0,0,0-1.6,1.6v3.414a1.6,1.6,0,1,0,3.2,0v-3.414A1.6,1.6,0,0,0,121.316,171.023Z"
                        transform="translate(-119.716 -171.023)"
                        fill="#ffa24f"
                      />
                    </g>
                  </g>
                  <g
                    id="Grupo_29"
                    data-name="Grupo 29"
                    transform="translate(23.905 97.426)"
                  >
                    <g
                      id="Grupo_28"
                      data-name="Grupo 28"
                    >
                      <path
                        id="Caminho_285"
                        data-name="Caminho 285"
                        d="M121.367,213.779h-.017a1.609,1.609,0,1,0,.017,0Z"
                        transform="translate(-119.758 -213.779)"
                        fill="#ffa24f"
                      />
                    </g>
                  </g>
                </g>
              </svg>

              <span>Professores</span>
            </div>
            <div class="fdn-features-plataforma-content-list-mobile-item">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="91.221"
                height="91.222"
                viewBox="0 0 91.221 91.222"
              >
                <g
                  id="elearning_1_"
                  data-name="elearning (1)"
                  transform="translate(-1 -0.999)"
                >
                  <path
                    id="Caminho_224"
                    data-name="Caminho 224"
                    d="M33.414,47a4.414,4.414,0,1,0,4.414,4.414A4.419,4.419,0,0,0,33.414,47Zm0,5.885a1.471,1.471,0,1,1,1.471-1.471A1.472,1.472,0,0,1,33.414,52.885Z"
                    transform="translate(13.197 21.681)"
                    fill="#ffa24f"
                  />
                  <path
                    id="Caminho_225"
                    data-name="Caminho 225"
                    d="M67.906,5.1a14.574,14.574,0,0,0-10.8-4.09A14.8,14.8,0,0,0,43,15.94a14.576,14.576,0,0,0,7.355,12.424v2.063a7.37,7.37,0,0,0,5.885,7.208v5.425L49.3,50a4.342,4.342,0,0,0-1.891-.443,4.414,4.414,0,1,0,4.414,4.414,4.342,4.342,0,0,0-.443-1.891l7.8-7.8V37.635a7.369,7.369,0,0,0,5.885-7.208V28.364A14.623,14.623,0,0,0,67.906,5.1ZM47.414,55.439a1.471,1.471,0,1,1,1.471-1.471A1.472,1.472,0,0,1,47.414,55.439Zm10.3-20.6A4.419,4.419,0,0,1,53.3,30.427V28.955h8.828v1.471A4.419,4.419,0,0,1,57.713,34.84Zm-.728-14.713h1.457l-.589,5.885h-.28Zm6.222,5.885h-2.4l.589-5.885h2.2a4.414,4.414,0,0,0,0-8.828h-.28a4.4,4.4,0,0,0-4.39,3.974l-.193,1.911H56.689l-.191-1.91a4.4,4.4,0,0,0-4.39-3.975h-.28a4.414,4.414,0,1,0,0,8.828h2.2l.589,5.885h-2.4A11.646,11.646,0,0,1,45.944,15.9,11.841,11.841,0,0,1,57.226,3.953c.163-.007.327-.01.49-.01a11.7,11.7,0,0,1,5.491,22.07Zm-1.514-8.828.162-1.618a1.466,1.466,0,0,1,1.462-1.324H63.6a1.471,1.471,0,1,1,0,2.943Zm-7.96,0H51.828a1.471,1.471,0,1,1,0-2.943h.28a1.465,1.465,0,0,1,1.462,1.324Z"
                    transform="translate(19.795 0)"
                    fill="#83c3e8"
                  />
                  <path
                    id="Caminho_226"
                    data-name="Caminho 226"
                    d="M31,5h2.943V7.943H31Z"
                    transform="translate(14.139 1.886)"
                    fill="#83c3e8"
                  />
                  <path
                    id="Caminho_227"
                    data-name="Caminho 227"
                    d="M35,5h2.943V7.943H35Z"
                    transform="translate(16.024 1.886)"
                    fill="#83c3e8"
                  />
                  <path
                    id="Caminho_228"
                    data-name="Caminho 228"
                    d="M39,5h2.943V7.943H39Z"
                    transform="translate(17.91 1.886)"
                    fill="#83c3e8"
                  />
                  <path
                    id="Caminho_229"
                    data-name="Caminho 229"
                    d="M10.414,9.828a4.408,4.408,0,0,0,4.143-2.943H24.683L29.1,9.828H39.84V6.885H29.987L25.573,3.943H14.557a4.406,4.406,0,1,0-4.143,5.885Zm0-5.885A1.471,1.471,0,1,1,8.943,5.414,1.473,1.473,0,0,1,10.414,3.943Z"
                    transform="translate(2.357 0)"
                    fill="#83c3e8"
                  />
                  <path
                    id="Caminho_230"
                    data-name="Caminho 230"
                    d="M14.557,15.943a4.414,4.414,0,1,0,0,2.943H25.573l4.414-2.943H39.84V13H29.1l-4.414,2.943Zm-4.143,2.943a1.471,1.471,0,1,1,1.471-1.471A1.473,1.473,0,0,1,10.414,18.885Z"
                    transform="translate(2.357 5.656)"
                    fill="#83c3e8"
                  />
                  <path
                    id="Caminho_231"
                    data-name="Caminho 231"
                    d="M31,13h2.943v2.943H31Z"
                    transform="translate(14.139 5.656)"
                    fill="#83c3e8"
                  />
                  <path
                    id="Caminho_232"
                    data-name="Caminho 232"
                    d="M35,13h8.828v2.943H35Z"
                    transform="translate(16.024 5.656)"
                    fill="#83c3e8"
                  />
                  <path
                    id="Caminho_233"
                    data-name="Caminho 233"
                    d="M9.557,12.885H42.2V9.943H9.557a4.414,4.414,0,1,0,0,2.943Zm-4.143,0a1.471,1.471,0,1,1,1.471-1.471A1.473,1.473,0,0,1,5.414,12.885Z"
                    transform="translate(0 2.828)"
                    fill="#83c3e8"
                  />
                  <path
                    id="Caminho_234"
                    data-name="Caminho 234"
                    d="M39,9h2.943v2.943H39Z"
                    transform="translate(17.91 3.771)"
                    fill="#83c3e8"
                  />
                  <path
                    id="Caminho_235"
                    data-name="Caminho 235"
                    d="M35,9h2.943v2.943H35Z"
                    transform="translate(16.024 3.771)"
                    fill="#83c3e8"
                  />
                  <path
                    id="Caminho_236"
                    data-name="Caminho 236"
                    d="M31,9h2.943v2.943H31Z"
                    transform="translate(14.139 3.771)"
                    fill="#83c3e8"
                  />
                  <path
                    id="Caminho_237"
                    data-name="Caminho 237"
                    d="M71.209,74.91a26.507,26.507,0,0,0-19.54-25.529A13.19,13.19,0,0,0,57.32,34.053c.684-.244,1.124-.416,1.193-.444l.925-.371v-5.41a2.946,2.946,0,0,0,2.943-2.943V21.943A2.946,2.946,0,0,0,59.438,19H30.012a2.946,2.946,0,0,0-2.943,2.943H25.6a4.419,4.419,0,0,0-4.414,4.414v7.849c-2.013,1.407-2.943,5.457-2.943,6.864a4.414,4.414,0,0,0,8.828,0c0-1.407-.93-5.456-2.943-6.864V26.357A1.473,1.473,0,0,1,25.6,24.885H27.07a2.946,2.946,0,0,0,2.943,2.943v5.41l.925.369c.069.028.509.2,1.193.444a13.192,13.192,0,0,0,5.651,15.33A26.507,26.507,0,0,0,18.242,74.91v5.885H5v2.943H84.451V80.795H71.209ZM22.656,42.541a1.472,1.472,0,0,1-1.471-1.471,9.121,9.121,0,0,1,1.471-4.29,9.11,9.11,0,0,1,1.471,4.29A1.472,1.472,0,0,1,22.656,42.541Zm45.61,38.254H65.285a7.28,7.28,0,0,0,1.51-4.414V74.91H63.852V61.668c0-.171-.038-.33-.05-.5A23.387,23.387,0,0,1,68.266,74.91ZM28.541,61.668a4.419,4.419,0,0,1,4.414-4.414H56.5a4.419,4.419,0,0,1,4.414,4.414V74.91H28.541Zm1.47-39.725H59.438v2.943H30.012ZM56.5,27.828v3.393c-2.11.756-7.426,2.492-11.77,2.492s-9.661-1.736-11.77-2.492V27.828Zm-22.07,10.3a10.216,10.216,0,0,1,.5-3.163,36.492,36.492,0,0,0,9.8,1.692,36.513,36.513,0,0,0,9.8-1.692,10.3,10.3,0,1,1-20.1,3.163ZM56.1,54.311H33.354a23.444,23.444,0,0,1,22.743,0ZM21.184,74.91a23.387,23.387,0,0,1,4.464-13.738c-.012.166-.05.325-.05.5V74.91H22.656v1.471a7.28,7.28,0,0,0,1.51,4.414H21.184Zm8.828,5.885a4.422,4.422,0,0,1-4.162-2.943H63.6a4.422,4.422,0,0,1-4.162,2.943Z"
                    transform="translate(1.885 8.484)"
                    fill="#ffa24f"
                  />
                  <path
                    id="Caminho_238"
                    data-name="Caminho 238"
                    d="M61,61h2.943v2.943H61Z"
                    transform="translate(28.278 28.279)"
                    fill="#ffa24f"
                  />
                  <path
                    id="Caminho_239"
                    data-name="Caminho 239"
                    d="M1,61H3.943v2.943H1Z"
                    transform="translate(0 28.279)"
                    fill="#ffa24f"
                  />
                </g>
              </svg>

              <span>Matriz de Capacitação</span>
            </div>
            <div class="fdn-features-plataforma-content-list-mobile-item">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="94"
                height="78.333"
                viewBox="0 0 94 78.333"
              >
                <g
                  id="Outline"
                  transform="translate(-2 -4)"
                >
                  <g
                    id="Grupo_13"
                    data-name="Grupo 13"
                    transform="translate(2 4)"
                  >
                    <path
                      id="Caminho_244"
                      data-name="Caminho 244"
                      d="M94.433,43.633A1.567,1.567,0,0,0,92.867,45.2v6.267a4.7,4.7,0,0,1-4.7,4.7H9.833a4.7,4.7,0,0,1-4.7-4.7v-32.9a1.567,1.567,0,0,0-3.133,0v32.9A7.843,7.843,0,0,0,9.833,59.3H30.2v6.267H20.8A7.843,7.843,0,0,0,12.967,73.4a1.567,1.567,0,0,0,1.567,1.567H59.967a1.567,1.567,0,0,0,0-3.133h-43.6A4.709,4.709,0,0,1,20.8,68.7H77.2a4.709,4.709,0,0,1,4.432,3.133h-15.4a1.567,1.567,0,0,0,0,3.133H83.467A1.567,1.567,0,0,0,85.033,73.4,7.843,7.843,0,0,0,77.2,65.567H67.8V59.3H88.167A7.843,7.843,0,0,0,96,51.467V45.2A1.567,1.567,0,0,0,94.433,43.633ZM64.667,65.567H33.333V59.3H64.667Z"
                      transform="translate(-2 3.367)"
                      fill="#65abd3"
                    />
                    <path
                      id="Caminho_245"
                      data-name="Caminho 245"
                      d="M88.167,4H9.833A7.843,7.843,0,0,0,2,11.833v7.833a1.567,1.567,0,0,0,3.133,0V11.833a4.7,4.7,0,0,1,4.7-4.7H88.167a4.7,4.7,0,0,1,4.7,4.7V46.3A1.567,1.567,0,0,0,96,46.3V11.833A7.843,7.843,0,0,0,88.167,4Z"
                      transform="translate(-2 -4)"
                      fill="#63a9d1"
                    />
                    <path
                      id="Caminho_246"
                      data-name="Caminho 246"
                      d="M41,27.567v18.8a1.567,1.567,0,0,0,1.567,1.567H64.5a1.567,1.567,0,0,0,1.567-1.567v-18.8A1.567,1.567,0,0,0,64.5,26H42.567A1.567,1.567,0,0,0,41,27.567Zm10.967,9.4a1.567,1.567,0,1,1,1.567,1.567A1.567,1.567,0,0,1,51.967,36.967ZM50.4,44.8V43.233a1.567,1.567,0,0,1,1.567-1.567H55.1a1.567,1.567,0,0,1,1.567,1.567V44.8ZM44.133,29.133h18.8V44.8H59.8V43.233a4.691,4.691,0,0,0-2.193-3.962,4.648,4.648,0,0,0,.627-2.3,4.7,4.7,0,0,0-9.4,0,4.648,4.648,0,0,0,.627,2.3,4.691,4.691,0,0,0-2.193,3.962V44.8H44.133Z"
                      transform="translate(20.1 8.467)"
                      fill="#ffa24f"
                    />
                    <path
                      id="Caminho_247"
                      data-name="Caminho 247"
                      d="M65.533,8H7.567A1.567,1.567,0,0,0,6,9.567V50.3a1.567,1.567,0,0,0,1.567,1.567H56.133a1.567,1.567,0,0,0,0-3.133h-47v-37.6H63.967V31.5a1.567,1.567,0,1,0,3.133,0V9.567A1.567,1.567,0,0,0,65.533,8Z"
                      transform="translate(0.267 -1.733)"
                      fill="#ffa24f"
                    />
                    <path
                      id="Caminho_248"
                      data-name="Caminho 248"
                      d="M48.567,18.967H61.1A1.567,1.567,0,0,0,62.667,17.4V9.567A1.567,1.567,0,0,0,61.1,8H48.567A1.567,1.567,0,0,0,47,9.567V17.4A1.567,1.567,0,0,0,48.567,18.967Zm1.567-7.833h9.4v4.7h-9.4Z"
                      transform="translate(23.5 -1.733)"
                      fill="#ffa24f"
                    />
                    <path
                      id="Caminho_249"
                      data-name="Caminho 249"
                      d="M47,26.4a1.567,1.567,0,0,0,1.567,1.567H61.1A1.567,1.567,0,0,0,62.667,26.4V18.567A1.567,1.567,0,0,0,61.1,17H48.567A1.567,1.567,0,0,0,47,18.567Zm3.133-6.267h9.4v4.7h-9.4Z"
                      transform="translate(23.5 3.367)"
                      fill="#ffa24f"
                    />
                    <path
                      id="Caminho_250"
                      data-name="Caminho 250"
                      d="M8.567,38a1.567,1.567,0,1,0,0,3.133H44.6A1.567,1.567,0,1,0,44.6,38Z"
                      transform="translate(0.833 15.267)"
                      fill="#ffa24f"
                    />
                    <path
                      id="Caminho_251"
                      data-name="Caminho 251"
                      d="M18.941,15.459a1.567,1.567,0,0,0-2.215,0L14.7,17.485l-2.026-2.026a1.567,1.567,0,1,0-2.215,2.215L12.485,19.7l-2.026,2.026a1.567,1.567,0,1,0,2.215,2.215L14.7,21.915l2.026,2.026a1.567,1.567,0,1,0,2.215-2.215L16.915,19.7l2.026-2.026A1.567,1.567,0,0,0,18.941,15.459Z"
                      transform="translate(2.533 2.233)"
                      fill="#ffa24f"
                    />
                    <path
                      id="Caminho_252"
                      data-name="Caminho 252"
                      d="M30.707,15.313a1.567,1.567,0,0,0-2.193.313l-1.1,1.462-1.1-1.462a1.567,1.567,0,1,0-2.507,1.88L25.458,19.7l-1.645,2.193a1.567,1.567,0,0,0,2.507,1.88l4.7-6.267a1.567,1.567,0,0,0-.313-2.193Z"
                      transform="translate(10.183 2.233)"
                      fill="#ffa24f"
                    />
                    <path
                      id="Caminho_253"
                      data-name="Caminho 253"
                      d="M35.659,23.534a1.567,1.567,0,0,0,1.41.866h4.7a1.567,1.567,0,0,0,0-3.133H40.2l2.82-3.76A1.567,1.567,0,0,0,41.769,15h-4.7a1.567,1.567,0,0,0,0,3.133h1.567l-2.82,3.76A1.567,1.567,0,0,0,35.659,23.534Z"
                      transform="translate(16.981 2.233)"
                      fill="#ffa24f"
                    />
                    <path
                      id="Caminho_254"
                      data-name="Caminho 254"
                      d="M22.7,15.5H19.567a1.567,1.567,0,1,0,0,3.133H22.7a1.567,1.567,0,0,0,0-3.133Z"
                      transform="translate(7.067 2.517)"
                      fill="#ffa24f"
                    />
                    <path
                      id="Caminho_255"
                      data-name="Caminho 255"
                      d="M22.7,18.5H19.567a1.567,1.567,0,1,0,0,3.133H22.7a1.567,1.567,0,0,0,0-3.133Z"
                      transform="translate(7.067 4.217)"
                      fill="#ffa24f"
                    />
                    <path
                      id="Caminho_256"
                      data-name="Caminho 256"
                      d="M33.417,23.333a1.567,1.567,0,0,0,1.567-1.567v-.783h.783a1.567,1.567,0,1,0,0-3.133h-.783v-.783a1.567,1.567,0,0,0-3.133,0v.783h-.783a1.567,1.567,0,1,0,0,3.133h.783v.783A1.567,1.567,0,0,0,33.417,23.333Z"
                      transform="translate(13.583 2.517)"
                      fill="#ffa24f"
                    />
                    <path
                      id="Caminho_257"
                      data-name="Caminho 257"
                      d="M18.941,24.459a1.567,1.567,0,0,0-2.215,0L14.7,26.485l-2.026-2.026a1.567,1.567,0,1,0-2.215,2.215L12.485,28.7l-2.026,2.026a1.567,1.567,0,1,0,2.215,2.215L14.7,30.915l2.026,2.026a1.567,1.567,0,1,0,2.215-2.215L16.915,28.7l2.026-2.026A1.567,1.567,0,0,0,18.941,24.459Z"
                      transform="translate(2.533 7.333)"
                      fill="#ffa24f"
                    />
                    <path
                      id="Caminho_258"
                      data-name="Caminho 258"
                      d="M22.7,24.5H19.567a1.567,1.567,0,1,0,0,3.133H22.7a1.567,1.567,0,1,0,0-3.133Z"
                      transform="translate(7.067 7.617)"
                      fill="#ffa24f"
                    />
                    <path
                      id="Caminho_259"
                      data-name="Caminho 259"
                      d="M22.7,27.5H19.567a1.567,1.567,0,1,0,0,3.133H22.7a1.567,1.567,0,0,0,0-3.133Z"
                      transform="translate(7.067 9.317)"
                      fill="#ffa24f"
                    />
                    <path
                      id="Caminho_260"
                      data-name="Caminho 260"
                      d="M25,25.567v6.267a1.567,1.567,0,1,0,3.133,0V25.567a1.567,1.567,0,0,0-3.133,0Z"
                      transform="translate(11.033 7.333)"
                      fill="#ffa24f"
                    />
                  </g>
                </g>
              </svg>

              <span>Aulas Particulares</span>
            </div>
          </div>
        </div>
        <div class="fdn-features-plataforma-cta">
          <a
            href="https://api.whatsapp.com/send?phone=+5511994478241&text=Ol%C3%A1%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto"
            target="_blank"
            class="btn btn-primary btn-lg"
          >Fale com um consultor</a>
          <img
            v-lazy="require('@/assets/images/bg-effect.png')"
            class="fdn-features-plataforma-cta-effect"
          >
        </div>
      </div>
    </section>

    <section
      id="tipos"
      class="fdn-features-tipos"
    >
      <div class="container">
        <div class="fdn-features-tipos-header">
          <div class="fdn-features-tipos-header-title">
            <h2>
              Flexível para atender
              <span>o seu negócio</span>
            </h2>
          </div>
          <div class="fdn-features-tipos-header-menu">
            <ul class="fdn-features-tipos-header-menu-options">
              <li
                changeTipo="empresa"
                class="active"
              >
                Para empresa
              </li>
              <li changeTipo="escola">
                Para instituições de ensino
              </li>
            </ul>
          </div>
        </div>
        <div
          class="fdn-features-tipos-tab"
          tipo="escola"
        >
          <div class="fdn-features-tipos-content">
            <div class="fdn-features-tipos-content-text">
              <img
                v-lazy="require('@/assets/images/logo_fastead_escolas.png')"
                alt="FastEAD Escolas"
              >
            </div>
            <div class="fdn-features-tipos-content-image">
              <img
                v-lazy="require('@/assets/images/features-escola.png')"
                alt="Benefícios e recursos para instituições de ensino"
              >
            </div>
            <div class="fdn-features-tipos-content-list">
              <div class="fdn-features-tipos-content-list-item">
                <svg
                  id="video_online"
                  data-name="video online"
                  xmlns="http://www.w3.org/2000/svg"
                  width="105.97"
                  height="105.97"
                  viewBox="0 0 105.97 105.97"
                >
                  <path
                    id="Caminho_286"
                    data-name="Caminho 286"
                    d="M102.672,44.388H70.257a17.485,17.485,0,0,0,2.3-7.065h.094a5.3,5.3,0,0,0,5.3-5.3V30.259a5.3,5.3,0,0,0-3.225-4.875l2.708-2.708a1.766,1.766,0,0,0,.517-1.249V17.9A15.913,15.913,0,0,0,62.05,2H47.92a15.913,15.913,0,0,0-15.9,15.9v3.532a1.766,1.766,0,0,0,.517,1.248l2.708,2.708a5.3,5.3,0,0,0-3.225,4.875v1.766a5.3,5.3,0,0,0,5.3,5.3h.09a17.485,17.485,0,0,0,2.3,7.065H7.3a5.3,5.3,0,0,0-5.3,5.3v52.985a5.3,5.3,0,0,0,5.3,5.3h95.373a5.3,5.3,0,0,0,5.3-5.3V49.687a5.3,5.3,0,0,0-5.3-5.3Zm-47.687,5.3A14.145,14.145,0,0,1,40.856,35.557V22.592L51.582,18l17.533,4.777v12.78A14.145,14.145,0,0,1,54.985,49.687ZM74.413,32.025a1.766,1.766,0,0,1-1.766,1.766v-5.3a1.766,1.766,0,0,1,1.766,1.766ZM35.557,17.9A12.379,12.379,0,0,1,47.92,5.532H62.05A12.379,12.379,0,0,1,74.413,17.9v2.8l-1.766,1.766V21.428a1.766,1.766,0,0,0-1.3-1.7l-19.428-5.3a1.766,1.766,0,0,0-1.16.081L38.394,19.8a1.766,1.766,0,0,0-1.07,1.623v1.035L35.557,20.7Zm0,14.129V30.259a1.766,1.766,0,0,1,1.766-1.766v5.3A1.766,1.766,0,0,1,35.557,32.025Zm68.881,70.647a1.766,1.766,0,0,1-1.766,1.766H7.3a1.766,1.766,0,0,1-1.766-1.766V49.687A1.766,1.766,0,0,1,7.3,47.92h35.1a17.609,17.609,0,0,0,25.182,0h35.1a1.766,1.766,0,0,1,1.766,1.766Z"
                    transform="translate(-2 -2)"
                    fill="#5da3cc"
                  />
                  <rect
                    id="Retângulo_29"
                    data-name="Retângulo 29"
                    width="10"
                    height="3"
                    transform="translate(88.691 7.611)"
                    fill="#5da3cc"
                  />
                  <rect
                    id="Retângulo_30"
                    data-name="Retângulo 30"
                    width="10"
                    height="3"
                    transform="translate(88.691 14.611)"
                    fill="#5da3cc"
                  />
                  <path
                    id="Caminho_287"
                    data-name="Caminho 287"
                    d="M67.428,2H53.3A5.3,5.3,0,0,0,48,7.3V21.428a5.3,5.3,0,0,0,5.3,5.3h3.036L55.118,31.6A1.766,1.766,0,0,0,57.935,33.4l8.347-6.678h1.146a5.3,5.3,0,0,0,5.3-5.3V7.3a5.3,5.3,0,0,0-5.3-5.3Zm1.766,19.428a1.766,1.766,0,0,1-1.766,1.766H65.662a1.766,1.766,0,0,0-1.1.387l-4.746,3.8.5-1.989A1.766,1.766,0,0,0,58.6,23.194H53.3a1.766,1.766,0,0,1-1.766-1.766V7.3A1.766,1.766,0,0,1,53.3,5.532H67.428A1.766,1.766,0,0,1,69.194,7.3Z"
                    transform="translate(33.244 -2)"
                    fill="#5da3cc"
                  />
                  <rect
                    id="Retângulo_31"
                    data-name="Retângulo 31"
                    width="10"
                    height="3"
                    transform="translate(7.691 14.611)"
                    fill="#5da3cc"
                  />
                  <rect
                    id="Retângulo_32"
                    data-name="Retângulo 32"
                    width="10"
                    height="3"
                    transform="translate(7.691 21.611)"
                    fill="#5da3cc"
                  />
                  <path
                    id="Caminho_288"
                    data-name="Caminho 288"
                    d="M7.3,30.726H8.535l10.152,6.768a1.766,1.766,0,0,0,2.693-1.9l-1.222-4.871h1.27a5.3,5.3,0,0,0,5.3-5.3V11.3a5.3,5.3,0,0,0-5.3-5.3H7.3A5.3,5.3,0,0,0,2,11.3V25.428A5.3,5.3,0,0,0,7.3,30.726ZM5.532,11.3A1.766,1.766,0,0,1,7.3,9.532H21.428A1.766,1.766,0,0,1,23.194,11.3V25.428a1.766,1.766,0,0,1-1.766,1.766H17.9a1.766,1.766,0,0,0-1.713,2.194l.657,2.633-6.794-4.53a1.777,1.777,0,0,0-.98-.3H7.3a1.766,1.766,0,0,1-1.766-1.766Z"
                    transform="translate(-2 1.065)"
                    fill="#5da3cc"
                  />
                  <path
                    id="Caminho_289"
                    data-name="Caminho 289"
                    d="M47.217,50.017,29.556,41.186A1.766,1.766,0,0,0,27,42.767V60.428a1.766,1.766,0,0,0,2.556,1.59l17.662-8.831a1.766,1.766,0,0,0,0-3.161ZM30.532,57.571V45.624L42.479,51.6Z"
                    transform="translate(17.154 27.88)"
                    fill="#5da3cc"
                  />
                  <path
                    id="Caminho_290"
                    data-name="Caminho 290"
                    d="M8,62.791a1.766,1.766,0,0,0,1.766,1.766H31.713a19.325,19.325,0,0,0,1.607,3.974l-2.566,2.561a1.766,1.766,0,0,1-1.259.53H21.8a5.3,5.3,0,1,0,0,3.532h7.69a5.272,5.272,0,0,0,3.757-1.56l2.081-2.081a19.389,19.389,0,0,0,30.113,0l2.075,2.081a5.272,5.272,0,0,0,3.762,1.56h7.69a5.3,5.3,0,1,0,0-3.532h-7.69a1.766,1.766,0,0,1-1.259-.53l-2.566-2.561a19.325,19.325,0,0,0,1.607-3.974H91.01a1.766,1.766,0,0,0,1.766-1.766A31.791,31.791,0,0,0,60.985,31H39.791A31.828,31.828,0,0,0,8,62.791Zm8.831,12.363A1.766,1.766,0,1,1,18.6,73.388,1.766,1.766,0,0,1,16.831,75.154Zm67.115-3.532a1.766,1.766,0,1,1-1.766,1.766A1.766,1.766,0,0,1,83.945,71.622ZM50.388,75.154a15.9,15.9,0,1,1,15.9-15.9A15.9,15.9,0,0,1,50.388,75.154Zm10.6-40.622A28.271,28.271,0,0,1,89.191,61.025H69.726c.053-.583.09-1.169.09-1.766a19.428,19.428,0,0,0-38.856,0c0,.6.037,1.183.09,1.766H11.587a28.3,28.3,0,0,1,28.2-26.493Z"
                    transform="translate(2.597 20.219)"
                    fill="#5da3cc"
                  />
                </svg>
                <span>Professor virtual</span>
              </div>
              <div class="fdn-features-tipos-content-list-item">
                <svg
                  id="study"
                  xmlns="http://www.w3.org/2000/svg"
                  width="104.798"
                  height="104.798"
                  viewBox="0 0 104.798 104.798"
                >
                  <path
                    id="Caminho_291"
                    data-name="Caminho 291"
                    d="M101.68,22.96H73.612V7.24A5.24,5.24,0,0,0,68.372,2H36.933a1.747,1.747,0,0,0-1.747,1.747V22.96H7.118A5.186,5.186,0,0,0,2,28.2V84.092a5.186,5.186,0,0,0,5.118,5.24H41.858l-1.165,6.987H38.679a1.747,1.747,0,0,0-1.4.7L32.042,104a1.747,1.747,0,0,0,1.4,2.795H75.359a1.747,1.747,0,0,0,1.4-2.795l-5.24-6.987a1.747,1.747,0,0,0-1.4-.7H68.1L66.94,89.332H101.68a5.186,5.186,0,0,0,5.118-5.24V28.2a5.186,5.186,0,0,0-5.118-5.24ZM45.666,5.493h3.493V13.5l-.512-.512a1.747,1.747,0,0,0-2.47,0l-.512.512Zm-6.987,0h3.493V17.72a1.747,1.747,0,0,0,2.982,1.235L47.413,16.7l2.258,2.258a1.747,1.747,0,0,0,2.982-1.235V5.493h15.72A1.747,1.747,0,0,1,70.119,7.24V42.173a1.747,1.747,0,0,1-1.747,1.747H38.679ZM56.146,66.625A1.747,1.747,0,1,1,54.4,64.879,1.747,1.747,0,0,1,56.146,66.625ZM7.118,26.453H35.186v3.493H17.72a5.24,5.24,0,0,0-5.24,5.24V51.227a5.24,5.24,0,1,0,3.493,0V35.186a1.747,1.747,0,0,1,1.747-1.747H35.186v3.493H31.693a5.24,5.24,0,0,0-5.24,5.24V58.214a5.24,5.24,0,1,0,3.493,0V42.173a1.747,1.747,0,0,1,1.747-1.747h3.493v5.24a1.747,1.747,0,0,0,1.747,1.747h15.72V61.707a5.24,5.24,0,1,0,3.493,0V47.412H68.372a5.24,5.24,0,0,0,5.24-5.24V40.426h3.493a1.747,1.747,0,0,1,1.747,1.747V58.214a5.24,5.24,0,1,0,3.493,0V42.173a5.24,5.24,0,0,0-5.24-5.24H73.612V33.439H91.078a1.747,1.747,0,0,1,1.747,1.747V51.227a5.24,5.24,0,1,0,3.493,0V35.186a5.24,5.24,0,0,0-5.24-5.24H73.612V26.453H101.68A1.712,1.712,0,0,1,103.3,28.2V75.359H5.493V28.2A1.712,1.712,0,0,1,7.118,26.453Zm8.855,29.693A1.747,1.747,0,1,1,14.227,54.4,1.747,1.747,0,0,1,15.973,56.146Zm13.973,6.987A1.747,1.747,0,1,1,28.2,61.385,1.747,1.747,0,0,1,29.946,63.132Zm52.4,0A1.747,1.747,0,1,1,80.6,61.385,1.747,1.747,0,0,1,82.345,63.132Zm13.973-6.987A1.747,1.747,0,1,1,94.572,54.4,1.747,1.747,0,0,1,96.318,56.146ZM71.865,103.3H36.933l2.62-3.493H69.245Zm-7.3-6.987H44.235L45.4,89.332h18Zm37.118-10.48H7.118a1.712,1.712,0,0,1-1.624-1.747v-5.24H103.3v5.24A1.712,1.712,0,0,1,101.68,85.838Z"
                    transform="translate(-2 -2)"
                    fill="#ffa24f"
                  />
                  <rect
                    id="Retângulo_33"
                    data-name="Retângulo 33"
                    width="4"
                    height="4"
                    transform="translate(50.127 78.454)"
                    fill="#ffa24f"
                  />
                </svg>

                <span>Secretaria Virtual</span>
              </div>
              <div class="fdn-features-tipos-content-list-item">
                <svg
                  id="books"
                  xmlns="http://www.w3.org/2000/svg"
                  width="102"
                  height="102"
                  viewBox="0 0 102 102"
                >
                  <g
                    id="Grupo_45"
                    data-name="Grupo 45"
                    transform="translate(0)"
                  >
                    <g
                      id="Grupo_44"
                      data-name="Grupo 44"
                    >
                      <path
                        id="Caminho_339"
                        data-name="Caminho 339"
                        d="M346.433,170.667h-3.4a1.7,1.7,0,0,0,0,3.4h3.4a1.7,1.7,0,0,0,0-3.4Z"
                        transform="translate(-273.333 -136.667)"
                        fill="#5da3cc"
                      />
                      <path
                        id="Caminho_340"
                        data-name="Caminho 340"
                        d="M346.433,204.8h-3.4a1.7,1.7,0,0,0,0,3.4h3.4a1.7,1.7,0,0,0,0-3.4Z"
                        transform="translate(-273.333 -164)"
                        fill="#5da3cc"
                      />
                      <path
                        id="Caminho_341"
                        data-name="Caminho 341"
                        d="M346.433,136.533h-3.4a1.7,1.7,0,0,0,0,3.4h3.4a1.7,1.7,0,0,0,0-3.4Z"
                        transform="translate(-273.333 -109.333)"
                        fill="#5da3cc"
                      />
                      <path
                        id="Caminho_342"
                        data-name="Caminho 342"
                        d="M346.433,409.6h-3.4a1.7,1.7,0,0,0,0,3.4h3.4a1.7,1.7,0,0,0,0-3.4Z"
                        transform="translate(-273.333 -328)"
                        fill="#5da3cc"
                      />
                      <path
                        id="Caminho_343"
                        data-name="Caminho 343"
                        d="M131.367,136.533h-10.2a1.7,1.7,0,0,0-1.7,1.7v23.8a1.7,1.7,0,0,0,1.7,1.7h10.2a1.7,1.7,0,0,0,1.7-1.7v-23.8A1.7,1.7,0,0,0,131.367,136.533Zm-1.7,23.8h-6.8v-20.4h6.8Z"
                        transform="translate(-95.667 -109.333)"
                        fill="#5da3cc"
                      />
                      <path
                        id="Caminho_344"
                        data-name="Caminho 344"
                        d="M131.367,409.6h-10.2a1.7,1.7,0,0,0,0,3.4h10.2a1.7,1.7,0,0,0,0-3.4Z"
                        transform="translate(-95.667 -328)"
                        fill="#5da3cc"
                      />
                      <path
                        id="Caminho_345"
                        data-name="Caminho 345"
                        d="M131.367,443.733h-10.2a1.7,1.7,0,1,0,0,3.4h10.2a1.7,1.7,0,1,0,0-3.4Z"
                        transform="translate(-95.667 -355.333)"
                        fill="#5da3cc"
                      />
                      <path
                        id="Caminho_346"
                        data-name="Caminho 346"
                        d="M346.433,443.733h-3.4a1.7,1.7,0,1,0,0,3.4h3.4a1.7,1.7,0,1,0,0-3.4Z"
                        transform="translate(-273.333 -355.333)"
                        fill="#5da3cc"
                      />
                      <path
                        id="Caminho_347"
                        data-name="Caminho 347"
                        d="M435.167,443.733h-6.8a1.7,1.7,0,1,0,0,3.4h6.8a1.7,1.7,0,1,0,0-3.4Z"
                        transform="translate(-341.667 -355.333)"
                        fill="#5da3cc"
                      />
                      <path
                        id="Caminho_348"
                        data-name="Caminho 348"
                        d="M249.134,138.234a1.7,1.7,0,0,0-1.7-1.7h-6.8a1.7,1.7,0,0,0-1.7,1.7v23.8a1.7,1.7,0,0,0,1.7,1.7h6.8a1.7,1.7,0,0,0,1.7-1.7Zm-3.4,22.1h-3.4v-20.4h3.4Z"
                        transform="translate(-191.334 -109.334)"
                        fill="#5da3cc"
                      />
                      <path
                        id="Caminho_349"
                        data-name="Caminho 349"
                        d="M435.167,409.6h-6.8a1.7,1.7,0,0,0,0,3.4h6.8a1.7,1.7,0,0,0,0-3.4Z"
                        transform="translate(-341.667 -328)"
                        fill="#5da3cc"
                      />
                      <path
                        id="Caminho_350"
                        data-name="Caminho 350"
                        d="M247.434,443.733h-6.8a1.7,1.7,0,1,0,0,3.4h6.8a1.7,1.7,0,1,0,0-3.4Z"
                        transform="translate(-191.334 -355.333)"
                        fill="#5da3cc"
                      />
                      <path
                        id="Caminho_351"
                        data-name="Caminho 351"
                        d="M428.367,225.2a1.7,1.7,0,0,0,1.7-1.7v-17a1.7,1.7,0,0,0-3.4,0v17A1.7,1.7,0,0,0,428.367,225.2Z"
                        transform="translate(-341.667 -164.001)"
                        fill="#5da3cc"
                      />
                      <path
                        id="Caminho_352"
                        data-name="Caminho 352"
                        d="M464.2,206.5a1.7,1.7,0,1,0-3.4,0v17a1.7,1.7,0,1,0,3.4,0Z"
                        transform="translate(-369.001 -164)"
                        fill="#5da3cc"
                      />
                      <path
                        id="Caminho_353"
                        data-name="Caminho 353"
                        d="M96.9,13.6H86.7a1.7,1.7,0,1,0,0,3.4H96.9a1.7,1.7,0,0,1,1.7,1.7V96.9a1.7,1.7,0,0,1-1.7,1.7H81.6V27.2H91.8a1.7,1.7,0,0,0,0-3.4H81.6V8.5a5.046,5.046,0,0,0-5.1-5.1H66.3a5.106,5.106,0,0,0-5.1,5.1V98.6h-17V10.2H56.1a1.7,1.7,0,0,0,0-3.4H44.2V5.1A5.046,5.046,0,0,0,39.1,0h-17A5.106,5.106,0,0,0,17,5.1V20.4H8.5a1.7,1.7,0,1,0,0,3.4H17V98.6H5.1a1.7,1.7,0,0,1-1.7-1.7V15.3a1.7,1.7,0,0,1,1.7-1.7h6.8a1.7,1.7,0,0,0,0-3.4H5.1A5.106,5.106,0,0,0,0,15.3V96.9A5.106,5.106,0,0,0,5.1,102H96.9a5.106,5.106,0,0,0,5.1-5.1V18.7A5.106,5.106,0,0,0,96.9,13.6Zm-56.1,85H20.4v-85H35.7a1.7,1.7,0,0,0,0-3.4H20.4V5.1a1.681,1.681,0,0,1,1.7-1.7h17a1.663,1.663,0,0,1,1.7,1.7V98.6Zm37.4,0H64.6V17h8.5a1.7,1.7,0,0,0,0-3.4H64.6V8.5a1.681,1.681,0,0,1,1.7-1.7H76.5a1.663,1.663,0,0,1,1.7,1.7Z"
                        transform="translate(0)"
                        fill="#5da3cc"
                      />
                      <path
                        id="Caminho_354"
                        data-name="Caminho 354"
                        d="M39.234,409.6h-3.4a1.7,1.7,0,0,0,0,3.4h3.4a1.7,1.7,0,1,0,0-3.4Z"
                        transform="translate(-27.334 -328)"
                        fill="#5da3cc"
                      />
                      <path
                        id="Caminho_355"
                        data-name="Caminho 355"
                        d="M39.234,443.733h-3.4a1.7,1.7,0,1,0,0,3.4h3.4a1.7,1.7,0,1,0,0-3.4Z"
                        transform="translate(-27.334 -355.333)"
                        fill="#5da3cc"
                      />
                      <path
                        id="Caminho_356"
                        data-name="Caminho 356"
                        d="M247.434,409.6h-6.8a1.7,1.7,0,1,0,0,3.4h6.8a1.7,1.7,0,1,0,0-3.4Z"
                        transform="translate(-191.334 -328)"
                        fill="#5da3cc"
                      />
                      <path
                        id="Caminho_357"
                        data-name="Caminho 357"
                        d="M39.234,204.8h-3.4a1.7,1.7,0,0,0,0,3.4h3.4a1.7,1.7,0,1,0,0-3.4Z"
                        transform="translate(-27.334 -164)"
                        fill="#5da3cc"
                      />
                      <path
                        id="Caminho_358"
                        data-name="Caminho 358"
                        d="M39.234,238.933h-3.4a1.7,1.7,0,0,0,0,3.4h3.4a1.7,1.7,0,1,0,0-3.4Z"
                        transform="translate(-27.334 -191.333)"
                        fill="#5da3cc"
                      />
                    </g>
                  </g>
                </svg>

                <span>Conteúdo pronto</span>
              </div>
              <div class="fdn-features-tipos-content-list-item">
                <svg
                  id="software"
                  xmlns="http://www.w3.org/2000/svg"
                  width="107.219"
                  height="107.219"
                  viewBox="0 0 107.219 107.219"
                >
                  <path
                    id="Caminho_299"
                    data-name="Caminho 299"
                    d="M120,208h55.339v3.459H120Zm0,0"
                    transform="translate(-94.06 -163.037)"
                    fill="#ffa24f"
                  />
                  <path
                    id="Caminho_300"
                    data-name="Caminho 300"
                    d="M272,240h22.481v3.459H272Zm0,0"
                    transform="translate(-213.203 -188.12)"
                    fill="#ffa24f"
                  />
                  <path
                    id="Caminho_301"
                    data-name="Caminho 301"
                    d="M120,240h22.481v3.459H120Zm0,0"
                    transform="translate(-94.06 -188.12)"
                    fill="#ffa24f"
                  />
                  <path
                    id="Caminho_302"
                    data-name="Caminho 302"
                    d="M272,176h22.481v3.459H272Zm0,0"
                    transform="translate(-213.203 -137.955)"
                    fill="#ffa24f"
                  />
                  <path
                    id="Caminho_303"
                    data-name="Caminho 303"
                    d="M120,176h22.481v3.459H120Zm0,0"
                    transform="translate(-94.06 -137.955)"
                    fill="#ffa24f"
                  />
                  <path
                    id="Caminho_304"
                    data-name="Caminho 304"
                    d="M120,144h55.339v3.459H120Zm0,0"
                    transform="translate(-94.06 -112.872)"
                    fill="#ffa24f"
                  />
                  <path
                    id="Caminho_305"
                    data-name="Caminho 305"
                    d="M386.376,115.318v-8.942A10.386,10.386,0,0,0,376,96v3.459a6.924,6.924,0,0,1,6.917,6.917v8.942a13.432,13.432,0,0,0,2.879,8.351,13.432,13.432,0,0,0-2.879,8.351v8.942A6.924,6.924,0,0,1,376,147.88v3.459a10.386,10.386,0,0,0,10.376-10.376V132.02a10.014,10.014,0,0,1,2.952-7.128l1.223-1.223-1.223-1.223a10.011,10.011,0,0,1-2.952-7.128Zm0,0"
                    transform="translate(-294.721 -75.248)"
                    fill="#ffa24f"
                  />
                  <path
                    id="Caminho_306"
                    data-name="Caminho 306"
                    d="M60.321,140.963V132.02a13.432,13.432,0,0,0-2.879-8.351,13.432,13.432,0,0,0,2.879-8.351v-8.942a6.924,6.924,0,0,1,6.917-6.917V96a10.386,10.386,0,0,0-10.376,10.376v8.942a10.014,10.014,0,0,1-2.952,7.128l-1.223,1.223,1.223,1.223a10.011,10.011,0,0,1,2.952,7.128v8.942a10.386,10.386,0,0,0,10.376,10.376V147.88a6.924,6.924,0,0,1-6.917-6.917Zm0,0"
                    transform="translate(-41.298 -75.248)"
                    fill="#ffa24f"
                  />
                  <path
                    id="Caminho_307"
                    data-name="Caminho 307"
                    d="M222.917,352a6.917,6.917,0,1,0,6.917,6.917A6.924,6.924,0,0,0,222.917,352Zm0,10.376a3.459,3.459,0,1,1,3.459-3.459A3.462,3.462,0,0,1,222.917,362.376Zm0,0"
                    transform="translate(-169.308 -275.909)"
                    fill="#ffa24f"
                  />
                  <path
                    id="Caminho_308"
                    data-name="Caminho 308"
                    d="M224.127,320a13.877,13.877,0,0,0-6.584,1.663l1.648,3.042a10.379,10.379,0,0,1,14.064,14.064l3.042,1.648A13.84,13.84,0,0,0,224.127,320Zm0,0"
                    transform="translate(-170.517 -250.827)"
                    fill="#ffa24f"
                  />
                  <path
                    id="Caminho_309"
                    data-name="Caminho 309"
                    d="M197.835,370.5a10.379,10.379,0,0,1-9.129-15.312l-3.042-1.648A13.84,13.84,0,0,0,204.418,372.3l-1.648-3.042A10.4,10.4,0,0,1,197.835,370.5Zm0,0"
                    transform="translate(-144.225 -277.119)"
                    fill="#ffa24f"
                  />
                  <path
                    id="Caminho_310"
                    data-name="Caminho 310"
                    d="M107.219,5.188A5.2,5.2,0,0,0,102.031,0H5.188A5.2,5.2,0,0,0,0,5.188V84.737H29.4v3.08L33.727,88.9a20.769,20.769,0,0,0,1.658,4l-2.3,3.828,6.8,6.8,3.829-2.3a20.557,20.557,0,0,0,4,1.658l1.087,4.332h9.618L59.5,102.89a20.77,20.77,0,0,0,4-1.658l3.829,2.3,6.8-6.8L71.83,92.9a20.559,20.559,0,0,0,1.658-4l4.332-1.087v-3.08h29.4ZM5.188,3.459h96.843a1.733,1.733,0,0,1,1.729,1.729v8.647H3.459V5.188A1.733,1.733,0,0,1,5.188,3.459ZM74.361,85.116l-3.711.928L70.4,87.064a17.158,17.158,0,0,1-2.053,4.949l-.548.894,1.972,3.284-2.982,2.982L63.51,97.2l-.894.548A17.181,17.181,0,0,1,57.667,99.8l-1.019.246-.93,3.713H51.5l-.928-3.711L49.554,99.8A17.157,17.157,0,0,1,44.6,97.75L43.71,97.2l-3.284,1.972-2.982-2.982,1.972-3.284-.548-.894a17.181,17.181,0,0,1-2.053-4.949l-.246-1.019-3.713-.93V80.9l3.711-.928.246-1.019A17.157,17.157,0,0,1,38.867,74l.548-.894-1.972-3.284,2.982-2.982,3.284,1.972.894-.548a17.181,17.181,0,0,1,4.949-2.053l1.019-.246.93-3.713h4.217l.928,3.711,1.019.246a17.157,17.157,0,0,1,4.949,2.053l.894.548,3.284-1.972,2.982,2.982L67.8,73.107,68.35,74A17.181,17.181,0,0,1,70.4,78.951l.246,1.019,3.713.93Zm3.459-3.838V78.2l-4.328-1.083a20.77,20.77,0,0,0-1.658-4l2.3-3.829-6.8-6.8-3.829,2.3a20.558,20.558,0,0,0-4-1.658L58.418,58.8H48.8l-1.083,4.328a20.77,20.77,0,0,0-4,1.658l-3.829-2.3-6.8,6.8,2.3,3.829a20.558,20.558,0,0,0-1.658,4L29.4,78.2v3.08H3.459V17.293h100.3V81.279Zm0,0"
                    fill="#ffa24f"
                  />
                  <path
                    id="Caminho_311"
                    data-name="Caminho 311"
                    d="M32,32h3.459v3.459H32Zm0,0"
                    transform="translate(-25.083 -25.083)"
                    fill="#ffa24f"
                  />
                  <path
                    id="Caminho_312"
                    data-name="Caminho 312"
                    d="M64,32h3.459v3.459H64Zm0,0"
                    transform="translate(-50.165 -25.083)"
                    fill="#ffa24f"
                  />
                  <path
                    id="Caminho_313"
                    data-name="Caminho 313"
                    d="M96,32h3.459v3.459H96Zm0,0"
                    transform="translate(-75.248 -25.083)"
                    fill="#ffa24f"
                  />
                </svg>

                <span>Tesouraria</span>
              </div>
              <div class="fdn-features-tipos-content-list-item">
                <svg
                  id="training"
                  xmlns="http://www.w3.org/2000/svg"
                  width="71.669"
                  height="71.669"
                  viewBox="0 0 71.669 71.669"
                >
                  <g
                    id="Grupo_31"
                    data-name="Grupo 31"
                    transform="translate(31.378 64.25)"
                  >
                    <g
                      id="Grupo_30"
                      data-name="Grupo 30"
                    >
                      <path
                        id="Caminho_292"
                        data-name="Caminho 292"
                        d="M231.749,459h-6.182a1.4,1.4,0,0,0,0,2.8h6.182a1.4,1.4,0,1,0,0-2.8Z"
                        transform="translate(-224.167 -459)"
                        fill="#ffa24f"
                      />
                    </g>
                  </g>
                  <g
                    id="Grupo_33"
                    data-name="Grupo 33"
                    transform="translate(47.873 10.133)"
                  >
                    <g
                      id="Grupo_32"
                      data-name="Grupo 32"
                    >
                      <path
                        id="Caminho_293"
                        data-name="Caminho 293"
                        d="M344.39,72.8a1.4,1.4,0,1,0,.41.99A1.41,1.41,0,0,0,344.39,72.8Z"
                        transform="translate(-342.001 -72.391)"
                        fill="#ffa24f"
                      />
                    </g>
                  </g>
                  <g
                    id="Grupo_35"
                    data-name="Grupo 35"
                    transform="translate(0 0)"
                  >
                    <g
                      id="Grupo_34"
                      data-name="Grupo 34"
                    >
                      <path
                        id="Caminho_294"
                        data-name="Caminho 294"
                        d="M70.269,21.555a1.4,1.4,0,0,0,1.4-1.4V11.533a1.4,1.4,0,0,0-.771-1.249l-.021-.011L49.881.139a1.4,1.4,0,0,0-1.217,0l-21,10.134a1.4,1.4,0,0,0,0,2.521l8.183,3.949V25.4a1.4,1.4,0,0,0,.014.194h-.389a4.665,4.665,0,0,0,0,9.329h.452a13.431,13.431,0,0,0,5.707,9.59H28.6a5.049,5.049,0,0,0-2.279.541V24.4A2.282,2.282,0,0,1,28.6,22.118h3.616a1.4,1.4,0,0,0,0-2.8H28.6A5.064,5.064,0,0,0,24.916,20.9a5.064,5.064,0,0,0-3.679-1.583H1.4a1.4,1.4,0,0,0-1.4,1.4V51.375a1.4,1.4,0,0,0,1.4,1.4H3.079V59.63H1.4A1.4,1.4,0,0,0,0,61.03v2.52a8.128,8.128,0,0,0,8.119,8.119H63.55a8.128,8.128,0,0,0,8.119-8.119V61.03a1.4,1.4,0,0,0-1.4-1.4h-1.68V39.053a1.4,1.4,0,1,0-2.8,0V59.63H5.879V52.775H48.432a1.4,1.4,0,0,0,1.4-1.4V46.893A13.442,13.442,0,0,0,62.617,34.924h.452a4.665,4.665,0,0,0,0-9.329H62.68a1.4,1.4,0,0,0,.014-.194V16.743l6.175-2.98v6.392A1.4,1.4,0,0,0,70.269,21.555ZM35.851,32.125h-.375a1.865,1.865,0,0,1,0-3.73h.375ZM2.8,22.118H21.237A2.282,2.282,0,0,1,23.516,24.4V45.055a5.047,5.047,0,0,0-2.279-.541H2.8ZM68.869,62.43v1.12a5.325,5.325,0,0,1-5.319,5.319H8.119A5.325,5.325,0,0,1,2.8,63.55V62.43ZM2.8,49.976V47.314H21.237a2.282,2.282,0,0,1,2.279,2.279,1.4,1.4,0,0,0,.054.382ZM62.694,28.395h.375a1.865,1.865,0,0,1,0,3.73h-.375ZM47.033,49.976H26.262a1.4,1.4,0,0,0,.054-.382A2.282,2.282,0,0,1,28.6,47.314H47.033ZM59.894,33.484a10.622,10.622,0,1,1-21.244,0V27.546l10.058,4.425a1.4,1.4,0,0,0,1.127,0l10.058-4.425Zm0-9L49.272,29.161,38.65,24.487V18.094l10.014,4.833a1.4,1.4,0,0,0,1.217,0l10.014-4.833v6.393ZM53.209,12.933H64.148l-14.876,7.18L31.5,11.533,49.272,2.954l14.876,7.179H53.209a1.4,1.4,0,1,0,0,2.8Z"
                        transform="translate(0 0)"
                        fill="#ffa24f"
                      />
                    </g>
                  </g>
                  <g
                    id="Grupo_37"
                    data-name="Grupo 37"
                    transform="translate(68.869 24.999)"
                  >
                    <g
                      id="Grupo_36"
                      data-name="Grupo 36"
                    >
                      <path
                        id="Caminho_295"
                        data-name="Caminho 295"
                        d="M494.389,179a1.4,1.4,0,1,0,.41.99A1.411,1.411,0,0,0,494.389,179Z"
                        transform="translate(-492 -178.59)"
                        fill="#ffa24f"
                      />
                    </g>
                  </g>
                  <g
                    id="Grupo_39"
                    data-name="Grupo 39"
                    transform="translate(16.351 35.275)"
                  >
                    <g
                      id="Grupo_38"
                      data-name="Grupo 38"
                    >
                      <path
                        id="Caminho_296"
                        data-name="Caminho 296"
                        d="M119.2,252.41a1.4,1.4,0,1,0,.41.99A1.413,1.413,0,0,0,119.2,252.41Z"
                        transform="translate(-116.81 -252)"
                        fill="#ffa24f"
                      />
                    </g>
                  </g>
                  <g
                    id="Grupo_41"
                    data-name="Grupo 41"
                    transform="translate(6.579 35.277)"
                  >
                    <g
                      id="Grupo_40"
                      data-name="Grupo 40"
                    >
                      <path
                        id="Caminho_297"
                        data-name="Caminho 297"
                        d="M52.879,252.017H48.4a1.4,1.4,0,0,0,0,2.8h4.479a1.4,1.4,0,1,0,0-2.8Z"
                        transform="translate(-47 -252.017)"
                        fill="#ffa24f"
                      />
                    </g>
                  </g>
                  <g
                    id="Grupo_43"
                    data-name="Grupo 43"
                    transform="translate(6.579 28.557)"
                  >
                    <g
                      id="Grupo_42"
                      data-name="Grupo 42"
                    >
                      <path
                        id="Caminho_298"
                        data-name="Caminho 298"
                        d="M58.171,204.012H48.4a1.4,1.4,0,0,0,0,2.8h9.771a1.4,1.4,0,1,0,0-2.8Z"
                        transform="translate(-47 -204.012)"
                        fill="#ffa24f"
                      />
                    </g>
                  </g>
                </svg>

                <span>Escola Virtual</span>
              </div>
              <div class="fdn-features-tipos-content-list-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="58.444"
                  height="67.36"
                  viewBox="0 0 58.444 67.36"
                >
                  <g
                    id="conversation"
                    transform="translate(-30.952)"
                  >
                    <path
                      id="Caminho_314"
                      data-name="Caminho 314"
                      d="M48.634,200.064a10.984,10.984,0,1,0-12.258,0,7.483,7.483,0,0,0-5.424,7.183v13.081a1.08,1.08,0,0,0,1.08,1.08H52.978a1.08,1.08,0,0,0,1.08-1.08V207.247A7.482,7.482,0,0,0,48.634,200.064ZM45,203.431l-1.312-1.494h2.624Zm-4.187-1.494,2.6,2.965-2.437,1.818L39.8,201.937Zm7.961.471q.206.093.4.2l-.384,3.938L46.58,204.9ZM33.682,190.953a8.823,8.823,0,1,1,8.823,8.823A8.833,8.833,0,0,1,33.682,190.953Zm-.569,16.294A5.318,5.318,0,0,1,37.592,202l1.674,6.815a1.08,1.08,0,0,0,1.695.608l2.955-2.2v12.027h-5.47V208.54a1.08,1.08,0,1,0-2.16,0v10.708H33.112Zm18.786,12H46.076V207.221l2.955,2.2a1.08,1.08,0,0,0,1.721-.761l.4-4.123a5.277,5.277,0,0,1,.744,2.706v12Z"
                      transform="translate(0 -154.049)"
                      fill="#5da3cc"
                    />
                    <path
                      id="Caminho_315"
                      data-name="Caminho 315"
                      d="M140.378,386.463a1.08,1.08,0,1,0-.346,1.275,1.09,1.09,0,0,0,.346-1.275Z"
                      transform="translate(-91.888 -330.232)"
                      fill="#5da3cc"
                    />
                    <path
                      id="Caminho_316"
                      data-name="Caminho 316"
                      d="M96.152,238.31a1.08,1.08,0,1,0-.42,1.325,1.091,1.091,0,0,0,.42-1.325Z"
                      transform="translate(-54.033 -203.416)"
                      fill="#5da3cc"
                    />
                    <path
                      id="Caminho_317"
                      data-name="Caminho 317"
                      d="M143.207,239.151a1.08,1.08,0,1,0,.363-1.287,1.091,1.091,0,0,0-.363,1.287C143.234,239.215,143.18,239.084,143.207,239.151Z"
                      transform="translate(-96.018 -203.43)"
                      fill="#5da3cc"
                    />
                    <path
                      id="Caminho_318"
                      data-name="Caminho 318"
                      d="M129.33,25.228a1.08,1.08,0,0,0,1.66-.911V21.8h1.1a3.359,3.359,0,0,0,3.356-3.356V7.156A3.359,3.359,0,0,0,132.09,3.8H120.9V3.356A3.36,3.36,0,0,0,117.544,0H97.72a3.36,3.36,0,0,0-3.356,3.356V14.647A3.36,3.36,0,0,0,97.72,18h1.1v2.513a1.08,1.08,0,0,0,1.661.911L105.852,18h3.058v.446a3.359,3.359,0,0,0,3.356,3.356h11.691Zm-23.793-9.385a1.081,1.081,0,0,0-.581.169l-3.978,2.535V16.923a1.08,1.08,0,0,0-1.08-1.08H97.72a1.2,1.2,0,0,1-1.2-1.2V3.356a1.2,1.2,0,0,1,1.2-1.2h19.824a1.2,1.2,0,0,1,1.2,1.2V3.8h-6.473a3.361,3.361,0,0,0-3.167,2.25h-8.243a1.08,1.08,0,1,0,0,2.16h8.055v1.806h-8.055a1.08,1.08,0,1,0,0,2.16h8.055v3.665Zm5.533,2.606V7.156a1.2,1.2,0,0,1,1.2-1.2H132.09a1.2,1.2,0,0,1,1.2,1.2V18.449a1.2,1.2,0,0,1-1.2,1.2h-2.179a1.08,1.08,0,0,0-1.08,1.08v1.624l-3.979-2.535a1.08,1.08,0,0,0-.58-.169H112.266A1.2,1.2,0,0,1,111.071,18.449Z"
                      transform="translate(-54.279)"
                      fill="#5da3cc"
                    />
                    <path
                      id="Caminho_319"
                      data-name="Caminho 319"
                      d="M234.018,70.56h13.551a1.08,1.08,0,1,0,0-2.16H234.018a1.08,1.08,0,1,0,0,2.16Z"
                      transform="translate(-172.895 -58.549)"
                      fill="#5da3cc"
                    />
                    <path
                      id="Caminho_320"
                      data-name="Caminho 320"
                      d="M248.649,97.018a1.08,1.08,0,0,0-1.08-1.08H234.018a1.08,1.08,0,0,0,0,2.16h13.551A1.08,1.08,0,0,0,248.649,97.018Z"
                      transform="translate(-172.895 -82.12)"
                      fill="#5da3cc"
                    />
                    <path
                      id="Caminho_321"
                      data-name="Caminho 321"
                      d="M293.986,200.064a10.984,10.984,0,1,0-12.258,0,7.482,7.482,0,0,0-5.424,7.183v13.081a1.08,1.08,0,0,0,1.08,1.08H298.33a1.08,1.08,0,0,0,1.08-1.08V207.247A7.482,7.482,0,0,0,293.986,200.064Zm-2.278,1.873a4.553,4.553,0,0,1-7.7,0Zm-12.675-10.984a8.824,8.824,0,1,1,8.824,8.823A8.833,8.833,0,0,1,279.034,190.953Zm-.569,16.294a5.317,5.317,0,0,1,3.3-4.913,6.722,6.722,0,0,0,12.191,0,5.318,5.318,0,0,1,3.3,4.913v3.834h-3.784V208.54a1.08,1.08,0,1,0-2.16,0v10.708H278.465v-12Zm15,12v-6.007h3.784v6.007Z"
                      transform="translate(-210.015 -154.049)"
                      fill="#ffa24f"
                    />
                    <path
                      id="Caminho_322"
                      data-name="Caminho 322"
                      d="M358.72,239.155a1.08,1.08,0,1,0,.233-1.178,1.091,1.091,0,0,0-.233,1.178C358.747,239.22,358.693,239.089,358.72,239.155Z"
                      transform="translate(-280.492 -203.434)"
                      fill="#ffa24f"
                    />
                    <path
                      id="Caminho_323"
                      data-name="Caminho 323"
                      d="M311.717,238.6a1.121,1.121,0,0,0-.16-.389,1.083,1.083,0,1,0,.16.389C311.7,238.53,311.732,238.67,311.717,238.6Z"
                      transform="translate(-238.497 -203.504)"
                      fill="#ffa24f"
                    />
                    <path
                      id="Caminho_324"
                      data-name="Caminho 324"
                      d="M329.121,269.334a.864.864,0,0,0-1.222,0,1.528,1.528,0,0,1-2.162,0,.864.864,0,1,0-1.222,1.222,3.26,3.26,0,0,0,4.605,0A.864.864,0,0,0,329.121,269.334Z"
                      transform="translate(-251.066 -230.326)"
                      fill="#ffa24f"
                    />
                  </g>
                </svg>
                <span>Pátio Virtual</span>
              </div>
              <div class="fdn-features-tipos-content-list-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="63.214"
                  height="59"
                  viewBox="0 0 63.214 59"
                >
                  <g
                    id="surface1"
                    transform="translate(0 -0.25)"
                  >
                    <path
                      id="Caminho_325"
                      data-name="Caminho 325"
                      d="M4.214,50.821H6.321V48.714H4.214a2.107,2.107,0,0,1-2.107-2.107V4.464A2.107,2.107,0,0,1,4.214,2.357H50.571a2.107,2.107,0,0,1,2.107,2.107V6.571h2.107V4.464A4.214,4.214,0,0,0,50.571.25H4.214A4.214,4.214,0,0,0,0,4.464V46.607A4.214,4.214,0,0,0,4.214,50.821Zm0,0"
                      fill="#5da3cc"
                    />
                    <path
                      id="Caminho_326"
                      data-name="Caminho 326"
                      d="M62.5,62.75h2.107v2.107H62.5Zm0,0"
                      transform="translate(-58.286 -58.286)"
                      fill="#5da3cc"
                    />
                    <path
                      id="Caminho_327"
                      data-name="Caminho 327"
                      d="M125,62.75h2.107v2.107H125Zm0,0"
                      transform="translate(-116.571 -58.286)"
                      fill="#5da3cc"
                    />
                    <path
                      id="Caminho_328"
                      data-name="Caminho 328"
                      d="M187.5,62.75h2.107v2.107H187.5Zm0,0"
                      transform="translate(-174.857 -58.286)"
                      fill="#5da3cc"
                    />
                    <path
                      id="Caminho_329"
                      data-name="Caminho 329"
                      d="M125,171.607a4.214,4.214,0,0,0,4.214,4.214h46.357a4.214,4.214,0,0,0,4.214-4.214V129.464a4.214,4.214,0,0,0-4.214-4.214H129.214A4.214,4.214,0,0,0,125,129.464Zm2.107-42.143a2.107,2.107,0,0,1,2.107-2.107h46.357a2.107,2.107,0,0,1,2.107,2.107v42.143a2.107,2.107,0,0,1-2.107,2.107H129.214a2.107,2.107,0,0,1-2.107-2.107Zm0,0"
                      transform="translate(-116.571 -116.571)"
                      fill="#5da3cc"
                    />
                    <path
                      id="Caminho_330"
                      data-name="Caminho 330"
                      d="M187.5,187.75h2.107v2.107H187.5Zm0,0"
                      transform="translate(-174.857 -174.857)"
                      fill="#5da3cc"
                    />
                    <path
                      id="Caminho_331"
                      data-name="Caminho 331"
                      d="M250,187.75h2.107v2.107H250Zm0,0"
                      transform="translate(-233.143 -174.857)"
                      fill="#5da3cc"
                    />
                    <path
                      id="Caminho_332"
                      data-name="Caminho 332"
                      d="M312.5,187.75h2.107v2.107H312.5Zm0,0"
                      transform="translate(-291.429 -174.857)"
                      fill="#5da3cc"
                    />
                    <path
                      id="Caminho_333"
                      data-name="Caminho 333"
                      d="M187.5,250.25h46.357v2.107H187.5Zm0,0"
                      transform="translate(-174.857 -233.143)"
                      fill="#5da3cc"
                    />
                    <path
                      id="Caminho_334"
                      data-name="Caminho 334"
                      d="M187.5,328.375h8.429v2.107H187.5Zm0,0"
                      transform="translate(-174.857 -306)"
                      fill="#5da3cc"
                    />
                    <path
                      id="Caminho_335"
                      data-name="Caminho 335"
                      d="M187.5,390.875h6.321v2.107H187.5Zm0,0"
                      transform="translate(-174.857 -364.286)"
                      fill="#5da3cc"
                    />
                    <path
                      id="Caminho_336"
                      data-name="Caminho 336"
                      d="M429.25,453.375a7.375,7.375,0,1,0,7.375,7.375A7.375,7.375,0,0,0,429.25,453.375Zm0,12.643a5.268,5.268,0,1,1,5.268-5.268A5.268,5.268,0,0,1,429.25,466.018Zm0,0"
                      transform="translate(-393.429 -422.571)"
                      fill="#5da3cc"
                    />
                    <path
                      id="Caminho_337"
                      data-name="Caminho 337"
                      d="M314.964,326.446a1.053,1.053,0,0,0-1.054-1.054h-2.785a13.4,13.4,0,0,0-.833-2.012l1.969-1.968a1.055,1.055,0,0,0,0-1.49l-4.47-4.47a1.055,1.055,0,0,0-1.49,0l-1.968,1.968a13.63,13.63,0,0,0-2.012-.832V313.8a1.053,1.053,0,0,0-1.054-1.054h-6.321a1.053,1.053,0,0,0-1.054,1.054v2.785a13.627,13.627,0,0,0-2.012.832l-1.968-1.968a1.055,1.055,0,0,0-1.49,0l-4.47,4.47a1.055,1.055,0,0,0,0,1.49l1.968,1.968c-.088.173-.173.346-.253.52a13.629,13.629,0,0,0-.579,1.492H282.3a1.053,1.053,0,0,0-1.054,1.054v6.321a1.053,1.053,0,0,0,1.054,1.054h2.785a13.4,13.4,0,0,0,.833,2.012l-1.969,1.968a1.055,1.055,0,0,0,0,1.49l4.47,4.47a1.055,1.055,0,0,0,1.49,0l1.968-1.968a13.627,13.627,0,0,0,2.012.832v2.785a1.053,1.053,0,0,0,1.054,1.054h6.321a1.053,1.053,0,0,0,1.054-1.054v-2.785a13.63,13.63,0,0,0,2.012-.832l1.968,1.968a1.055,1.055,0,0,0,1.49,0l4.47-4.47a1.055,1.055,0,0,0,0-1.49l-1.969-1.968a13.4,13.4,0,0,0,.833-2.012h2.785a1.053,1.053,0,0,0,1.054-1.054Zm-2.107,5.268h-2.522a1.054,1.054,0,0,0-1.02.79,11.522,11.522,0,0,1-1.233,2.979,1.054,1.054,0,0,0,.162,1.28l1.781,1.782-2.98,2.981-1.782-1.783a1.054,1.054,0,0,0-1.281-.162A11.51,11.51,0,0,1,301,340.815a1.054,1.054,0,0,0-.79,1.02v2.522H296v-2.522a1.054,1.054,0,0,0-.79-1.02,11.509,11.509,0,0,1-2.979-1.233,1.054,1.054,0,0,0-1.281.162l-1.782,1.783-2.98-2.981,1.781-1.782a1.054,1.054,0,0,0,.162-1.28A11.519,11.519,0,0,1,286.9,332.5a1.054,1.054,0,0,0-1.02-.79h-2.522V327.5h2.522a1.054,1.054,0,0,0,1.02-.79,11.445,11.445,0,0,1,.685-1.931,11.629,11.629,0,0,1,.548-1.054,1.054,1.054,0,0,0-.162-1.279l-1.781-1.782,2.98-2.981,1.782,1.783a1.053,1.053,0,0,0,1.281.161,11.523,11.523,0,0,1,2.979-1.233,1.053,1.053,0,0,0,.79-1.019v-2.518h4.214v2.522a1.054,1.054,0,0,0,.79,1.02,11.508,11.508,0,0,1,2.979,1.233,1.054,1.054,0,0,0,1.281-.162l1.782-1.783,2.98,2.981-1.781,1.782a1.054,1.054,0,0,0-.162,1.28,11.521,11.521,0,0,1,1.233,2.979,1.054,1.054,0,0,0,1.02.79h2.522Zm0,0"
                      transform="translate(-262.286 -291.429)"
                      fill="#5da3cc"
                    />
                    <path
                      id="Caminho_338"
                      data-name="Caminho 338"
                      d="M781.25,781.5h6.321v2.107H781.25Zm0,0"
                      transform="translate(-728.571 -728.571)"
                      fill="#5da3cc"
                    />
                  </g>
                </svg>

                <span>Personalização</span>
              </div>
              <div class="fdn-features-tipos-content-list-item">
                <svg
                  id="implement"
                  xmlns="http://www.w3.org/2000/svg"
                  width="68"
                  height="68"
                  viewBox="0 0 68 68"
                >
                  <path
                    id="Caminho_359"
                    data-name="Caminho 359"
                    d="M5.667,143.533h51A1.133,1.133,0,0,0,57.8,142.4V112.933H55.533v28.333H5.667a3.4,3.4,0,1,1,0-6.8H7.933a1.133,1.133,0,0,0,1.133-1.133v-34H27.2V97.067H9.067V89.133A1.133,1.133,0,0,0,7.933,88H5.667A5.673,5.673,0,0,0,0,93.667v44.2A5.673,5.673,0,0,0,5.667,143.533ZM2.267,98.2V93.667a3.4,3.4,0,0,1,3.4-3.4H6.8V132.2H5.667a5.644,5.644,0,0,0-3.4,1.133Zm0,0"
                    transform="translate(0 -75.533)"
                    fill="#ffb97a"
                  />
                  <path
                    id="Caminho_360"
                    data-name="Caminho 360"
                    d="M94.733,192h-13.6A1.133,1.133,0,0,0,80,193.133V202.2a1.133,1.133,0,0,0,1.133,1.133h13.6a1.133,1.133,0,0,0,1.133-1.133v-3.4H100.4v-2.267H95.867v-3.4A1.133,1.133,0,0,0,94.733,192ZM93.6,201.067H82.267v-6.8H93.6Zm0,0"
                    transform="translate(-68.667 -164.8)"
                    fill="#ffb97a"
                  />
                  <path
                    id="Caminho_361"
                    data-name="Caminho 361"
                    d="M106.067,306.133h13.6A1.133,1.133,0,0,0,120.8,305v-9.067a1.133,1.133,0,0,0-1.133-1.133H114V288h-2.267v6.8h-5.667a1.133,1.133,0,0,0-1.133,1.133v3.4H95.867v-3.4a1.133,1.133,0,0,0-1.133-1.133h-13.6A1.133,1.133,0,0,0,80,295.933V305a1.133,1.133,0,0,0,1.133,1.133h13.6A1.133,1.133,0,0,0,95.867,305v-3.4h9.067V305A1.133,1.133,0,0,0,106.067,306.133Zm1.133-9.067h11.333v6.8H107.2Zm-13.6,6.8H82.267v-6.8H93.6Zm0,0"
                    transform="translate(-68.667 -247.2)"
                    fill="#ffb97a"
                  />
                  <path
                    id="Caminho_362"
                    data-name="Caminho 362"
                    d="M282.2,64a10.2,10.2,0,1,0,10.2,10.2A10.2,10.2,0,0,0,282.2,64Zm0,18.133a7.933,7.933,0,1,1,7.933-7.933A7.933,7.933,0,0,1,282.2,82.133Zm0,0"
                    transform="translate(-233.467 -54.933)"
                    fill="#ffb97a"
                  />
                  <path
                    id="Caminho_363"
                    data-name="Caminho 363"
                    d="M245.4,23.8a1.133,1.133,0,0,0,1.133-1.133v-6.8a1.133,1.133,0,0,0-1.133-1.133h-4.129a15.014,15.014,0,0,0-.9-2.165L243.3,9.655a1.133,1.133,0,0,0,0-1.6l-4.812-4.817a1.132,1.132,0,0,0-1.6,0l-2.919,2.924a15.076,15.076,0,0,0-2.165-.9V1.133A1.133,1.133,0,0,0,230.667,0h-6.8a1.133,1.133,0,0,0-1.133,1.133V5.262a15.076,15.076,0,0,0-2.165.9l-2.915-2.924a1.132,1.132,0,0,0-1.6,0l-4.818,4.813a1.133,1.133,0,0,0,0,1.6l2.924,2.914a14.984,14.984,0,0,0-.9,2.168h-4.129A1.133,1.133,0,0,0,208,15.867v6.8a1.133,1.133,0,0,0,1.133,1.133h4.129a15.012,15.012,0,0,0,.9,2.165l-2.924,2.914a1.133,1.133,0,0,0,0,1.606l4.812,4.8a1.134,1.134,0,0,0,1.6,0l2.915-2.914a15.154,15.154,0,0,0,2.169.9V37.4a1.133,1.133,0,0,0,1.133,1.133h6.8A1.133,1.133,0,0,0,231.8,37.4V33.271a15.089,15.089,0,0,0,2.166-.9l2.915,2.914a1.134,1.134,0,0,0,1.6,0l4.817-4.806a1.133,1.133,0,0,0,0-1.606l-2.924-2.914a15,15,0,0,0,.9-2.164Zm-6.07-1.422a12.962,12.962,0,0,1-1.335,3.22,1.133,1.133,0,0,0,.179,1.374l2.72,2.711-3.211,3.2-2.71-2.713a1.134,1.134,0,0,0-1.372-.178,12.908,12.908,0,0,1-3.22,1.334,1.134,1.134,0,0,0-.848,1.1v3.842H225V32.424a1.133,1.133,0,0,0-.844-1.1,12.908,12.908,0,0,1-3.22-1.334,1.134,1.134,0,0,0-1.372.178l-2.712,2.712-3.211-3.2,2.72-2.711a1.133,1.133,0,0,0,.179-1.374,12.961,12.961,0,0,1-1.335-3.22,1.133,1.133,0,0,0-1.1-.844h-3.842V17h3.842a1.133,1.133,0,0,0,1.095-.844,12.962,12.962,0,0,1,1.335-3.22,1.133,1.133,0,0,0-.179-1.374l-2.72-2.712,3.212-3.212,2.712,2.72a1.133,1.133,0,0,0,1.374.179,12.908,12.908,0,0,1,3.22-1.334A1.134,1.134,0,0,0,225,6.109V2.267h4.533V6.109a1.133,1.133,0,0,0,.844,1.1,12.908,12.908,0,0,1,3.22,1.334,1.133,1.133,0,0,0,1.376-.179l2.712-2.72L240.9,8.851l-2.72,2.712A1.132,1.132,0,0,0,238,12.937a12.981,12.981,0,0,1,1.335,3.22,1.133,1.133,0,0,0,1.091.843h3.842v4.533h-3.842a1.133,1.133,0,0,0-1.095.844Zm0,0"
                    transform="translate(-178.533)"
                    fill="#ffb97a"
                  />
                  <path
                    id="Caminho_364"
                    data-name="Caminho 364"
                    d="M316.533,104a4.533,4.533,0,1,0,4.533,4.533A4.534,4.534,0,0,0,316.533,104Zm0,6.8a2.267,2.267,0,1,1,2.267-2.267A2.267,2.267,0,0,1,316.533,110.8Zm0,0"
                    transform="translate(-267.8 -89.267)"
                    fill="#ffb97a"
                  />
                </svg>

                <span>Gerenciamento de Conteúdo</span>
              </div>
            </div>
          </div>
          <div class="fdn-features-tipos-footer">
            <div class="fdn-features-tipos-footer-actions">
              <a
                href="https://api.whatsapp.com/send?phone=+5511994478241&text=Ol%C3%A1%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto"
                target="_blank"
                class="btn btn-default btn-lg"
              >Fale com um consultor</a>
            </div>
          </div>
        </div>
        <div
          class="fdn-features-tipos-tab active"
          tipo="empresa"
        >
          <div class="fdn-features-tipos-content">
            <div class="fdn-features-tipos-content-text">
              <img
                v-lazy="require('@/assets/images/logo_fastead_business.png')"
                alt="FastEAD Business"
              >
            </div>
            <div class="fdn-features-tipos-content-image">
              <img
                v-lazy="require('@/assets/images/features-business.png')"
                alt="Benefícios e recursos para sua empresa"
              >
            </div>
            <div class="fdn-features-tipos-content-list">
              <div class="fdn-features-tipos-content-list-item">
                <svg
                  id="study"
                  xmlns="http://www.w3.org/2000/svg"
                  width="104.798"
                  height="104.798"
                  viewBox="0 0 104.798 104.798"
                >
                  <path
                    id="Caminho_291"
                    data-name="Caminho 291"
                    d="M101.68,22.96H73.612V7.24A5.24,5.24,0,0,0,68.372,2H36.933a1.747,1.747,0,0,0-1.747,1.747V22.96H7.118A5.186,5.186,0,0,0,2,28.2V84.092a5.186,5.186,0,0,0,5.118,5.24H41.858l-1.165,6.987H38.679a1.747,1.747,0,0,0-1.4.7L32.042,104a1.747,1.747,0,0,0,1.4,2.795H75.359a1.747,1.747,0,0,0,1.4-2.795l-5.24-6.987a1.747,1.747,0,0,0-1.4-.7H68.1L66.94,89.332H101.68a5.186,5.186,0,0,0,5.118-5.24V28.2a5.186,5.186,0,0,0-5.118-5.24ZM45.666,5.493h3.493V13.5l-.512-.512a1.747,1.747,0,0,0-2.47,0l-.512.512Zm-6.987,0h3.493V17.72a1.747,1.747,0,0,0,2.982,1.235L47.413,16.7l2.258,2.258a1.747,1.747,0,0,0,2.982-1.235V5.493h15.72A1.747,1.747,0,0,1,70.119,7.24V42.173a1.747,1.747,0,0,1-1.747,1.747H38.679ZM56.146,66.625A1.747,1.747,0,1,1,54.4,64.879,1.747,1.747,0,0,1,56.146,66.625ZM7.118,26.453H35.186v3.493H17.72a5.24,5.24,0,0,0-5.24,5.24V51.227a5.24,5.24,0,1,0,3.493,0V35.186a1.747,1.747,0,0,1,1.747-1.747H35.186v3.493H31.693a5.24,5.24,0,0,0-5.24,5.24V58.214a5.24,5.24,0,1,0,3.493,0V42.173a1.747,1.747,0,0,1,1.747-1.747h3.493v5.24a1.747,1.747,0,0,0,1.747,1.747h15.72V61.707a5.24,5.24,0,1,0,3.493,0V47.412H68.372a5.24,5.24,0,0,0,5.24-5.24V40.426h3.493a1.747,1.747,0,0,1,1.747,1.747V58.214a5.24,5.24,0,1,0,3.493,0V42.173a5.24,5.24,0,0,0-5.24-5.24H73.612V33.439H91.078a1.747,1.747,0,0,1,1.747,1.747V51.227a5.24,5.24,0,1,0,3.493,0V35.186a5.24,5.24,0,0,0-5.24-5.24H73.612V26.453H101.68A1.712,1.712,0,0,1,103.3,28.2V75.359H5.493V28.2A1.712,1.712,0,0,1,7.118,26.453Zm8.855,29.693A1.747,1.747,0,1,1,14.227,54.4,1.747,1.747,0,0,1,15.973,56.146Zm13.973,6.987A1.747,1.747,0,1,1,28.2,61.385,1.747,1.747,0,0,1,29.946,63.132Zm52.4,0A1.747,1.747,0,1,1,80.6,61.385,1.747,1.747,0,0,1,82.345,63.132Zm13.973-6.987A1.747,1.747,0,1,1,94.572,54.4,1.747,1.747,0,0,1,96.318,56.146ZM71.865,103.3H36.933l2.62-3.493H69.245Zm-7.3-6.987H44.235L45.4,89.332h18Zm37.118-10.48H7.118a1.712,1.712,0,0,1-1.624-1.747v-5.24H103.3v5.24A1.712,1.712,0,0,1,101.68,85.838Z"
                    transform="translate(-2 -2)"
                    fill="#ffa24f"
                  />
                  <rect
                    id="Retângulo_33"
                    data-name="Retângulo 33"
                    width="4"
                    height="4"
                    transform="translate(50.127 78.454)"
                    fill="#ffa24f"
                  />
                </svg>

                <span>Plataforma EAD</span>
              </div>
              <div class="fdn-features-tipos-content-list-item">
                <svg
                  id="books"
                  xmlns="http://www.w3.org/2000/svg"
                  width="102"
                  height="102"
                  viewBox="0 0 102 102"
                >
                  <g
                    id="Grupo_45"
                    data-name="Grupo 45"
                    transform="translate(0)"
                  >
                    <g
                      id="Grupo_44"
                      data-name="Grupo 44"
                    >
                      <path
                        id="Caminho_339"
                        data-name="Caminho 339"
                        d="M346.433,170.667h-3.4a1.7,1.7,0,0,0,0,3.4h3.4a1.7,1.7,0,0,0,0-3.4Z"
                        transform="translate(-273.333 -136.667)"
                        fill="#5da3cc"
                      />
                      <path
                        id="Caminho_340"
                        data-name="Caminho 340"
                        d="M346.433,204.8h-3.4a1.7,1.7,0,0,0,0,3.4h3.4a1.7,1.7,0,0,0,0-3.4Z"
                        transform="translate(-273.333 -164)"
                        fill="#5da3cc"
                      />
                      <path
                        id="Caminho_341"
                        data-name="Caminho 341"
                        d="M346.433,136.533h-3.4a1.7,1.7,0,0,0,0,3.4h3.4a1.7,1.7,0,0,0,0-3.4Z"
                        transform="translate(-273.333 -109.333)"
                        fill="#5da3cc"
                      />
                      <path
                        id="Caminho_342"
                        data-name="Caminho 342"
                        d="M346.433,409.6h-3.4a1.7,1.7,0,0,0,0,3.4h3.4a1.7,1.7,0,0,0,0-3.4Z"
                        transform="translate(-273.333 -328)"
                        fill="#5da3cc"
                      />
                      <path
                        id="Caminho_343"
                        data-name="Caminho 343"
                        d="M131.367,136.533h-10.2a1.7,1.7,0,0,0-1.7,1.7v23.8a1.7,1.7,0,0,0,1.7,1.7h10.2a1.7,1.7,0,0,0,1.7-1.7v-23.8A1.7,1.7,0,0,0,131.367,136.533Zm-1.7,23.8h-6.8v-20.4h6.8Z"
                        transform="translate(-95.667 -109.333)"
                        fill="#5da3cc"
                      />
                      <path
                        id="Caminho_344"
                        data-name="Caminho 344"
                        d="M131.367,409.6h-10.2a1.7,1.7,0,0,0,0,3.4h10.2a1.7,1.7,0,0,0,0-3.4Z"
                        transform="translate(-95.667 -328)"
                        fill="#5da3cc"
                      />
                      <path
                        id="Caminho_345"
                        data-name="Caminho 345"
                        d="M131.367,443.733h-10.2a1.7,1.7,0,1,0,0,3.4h10.2a1.7,1.7,0,1,0,0-3.4Z"
                        transform="translate(-95.667 -355.333)"
                        fill="#5da3cc"
                      />
                      <path
                        id="Caminho_346"
                        data-name="Caminho 346"
                        d="M346.433,443.733h-3.4a1.7,1.7,0,1,0,0,3.4h3.4a1.7,1.7,0,1,0,0-3.4Z"
                        transform="translate(-273.333 -355.333)"
                        fill="#5da3cc"
                      />
                      <path
                        id="Caminho_347"
                        data-name="Caminho 347"
                        d="M435.167,443.733h-6.8a1.7,1.7,0,1,0,0,3.4h6.8a1.7,1.7,0,1,0,0-3.4Z"
                        transform="translate(-341.667 -355.333)"
                        fill="#5da3cc"
                      />
                      <path
                        id="Caminho_348"
                        data-name="Caminho 348"
                        d="M249.134,138.234a1.7,1.7,0,0,0-1.7-1.7h-6.8a1.7,1.7,0,0,0-1.7,1.7v23.8a1.7,1.7,0,0,0,1.7,1.7h6.8a1.7,1.7,0,0,0,1.7-1.7Zm-3.4,22.1h-3.4v-20.4h3.4Z"
                        transform="translate(-191.334 -109.334)"
                        fill="#5da3cc"
                      />
                      <path
                        id="Caminho_349"
                        data-name="Caminho 349"
                        d="M435.167,409.6h-6.8a1.7,1.7,0,0,0,0,3.4h6.8a1.7,1.7,0,0,0,0-3.4Z"
                        transform="translate(-341.667 -328)"
                        fill="#5da3cc"
                      />
                      <path
                        id="Caminho_350"
                        data-name="Caminho 350"
                        d="M247.434,443.733h-6.8a1.7,1.7,0,1,0,0,3.4h6.8a1.7,1.7,0,1,0,0-3.4Z"
                        transform="translate(-191.334 -355.333)"
                        fill="#5da3cc"
                      />
                      <path
                        id="Caminho_351"
                        data-name="Caminho 351"
                        d="M428.367,225.2a1.7,1.7,0,0,0,1.7-1.7v-17a1.7,1.7,0,0,0-3.4,0v17A1.7,1.7,0,0,0,428.367,225.2Z"
                        transform="translate(-341.667 -164.001)"
                        fill="#5da3cc"
                      />
                      <path
                        id="Caminho_352"
                        data-name="Caminho 352"
                        d="M464.2,206.5a1.7,1.7,0,1,0-3.4,0v17a1.7,1.7,0,1,0,3.4,0Z"
                        transform="translate(-369.001 -164)"
                        fill="#5da3cc"
                      />
                      <path
                        id="Caminho_353"
                        data-name="Caminho 353"
                        d="M96.9,13.6H86.7a1.7,1.7,0,1,0,0,3.4H96.9a1.7,1.7,0,0,1,1.7,1.7V96.9a1.7,1.7,0,0,1-1.7,1.7H81.6V27.2H91.8a1.7,1.7,0,0,0,0-3.4H81.6V8.5a5.046,5.046,0,0,0-5.1-5.1H66.3a5.106,5.106,0,0,0-5.1,5.1V98.6h-17V10.2H56.1a1.7,1.7,0,0,0,0-3.4H44.2V5.1A5.046,5.046,0,0,0,39.1,0h-17A5.106,5.106,0,0,0,17,5.1V20.4H8.5a1.7,1.7,0,1,0,0,3.4H17V98.6H5.1a1.7,1.7,0,0,1-1.7-1.7V15.3a1.7,1.7,0,0,1,1.7-1.7h6.8a1.7,1.7,0,0,0,0-3.4H5.1A5.106,5.106,0,0,0,0,15.3V96.9A5.106,5.106,0,0,0,5.1,102H96.9a5.106,5.106,0,0,0,5.1-5.1V18.7A5.106,5.106,0,0,0,96.9,13.6Zm-56.1,85H20.4v-85H35.7a1.7,1.7,0,0,0,0-3.4H20.4V5.1a1.681,1.681,0,0,1,1.7-1.7h17a1.663,1.663,0,0,1,1.7,1.7V98.6Zm37.4,0H64.6V17h8.5a1.7,1.7,0,0,0,0-3.4H64.6V8.5a1.681,1.681,0,0,1,1.7-1.7H76.5a1.663,1.663,0,0,1,1.7,1.7Z"
                        transform="translate(0)"
                        fill="#5da3cc"
                      />
                      <path
                        id="Caminho_354"
                        data-name="Caminho 354"
                        d="M39.234,409.6h-3.4a1.7,1.7,0,0,0,0,3.4h3.4a1.7,1.7,0,1,0,0-3.4Z"
                        transform="translate(-27.334 -328)"
                        fill="#5da3cc"
                      />
                      <path
                        id="Caminho_355"
                        data-name="Caminho 355"
                        d="M39.234,443.733h-3.4a1.7,1.7,0,1,0,0,3.4h3.4a1.7,1.7,0,1,0,0-3.4Z"
                        transform="translate(-27.334 -355.333)"
                        fill="#5da3cc"
                      />
                      <path
                        id="Caminho_356"
                        data-name="Caminho 356"
                        d="M247.434,409.6h-6.8a1.7,1.7,0,1,0,0,3.4h6.8a1.7,1.7,0,1,0,0-3.4Z"
                        transform="translate(-191.334 -328)"
                        fill="#5da3cc"
                      />
                      <path
                        id="Caminho_357"
                        data-name="Caminho 357"
                        d="M39.234,204.8h-3.4a1.7,1.7,0,0,0,0,3.4h3.4a1.7,1.7,0,1,0,0-3.4Z"
                        transform="translate(-27.334 -164)"
                        fill="#5da3cc"
                      />
                      <path
                        id="Caminho_358"
                        data-name="Caminho 358"
                        d="M39.234,238.933h-3.4a1.7,1.7,0,0,0,0,3.4h3.4a1.7,1.7,0,1,0,0-3.4Z"
                        transform="translate(-27.334 -191.333)"
                        fill="#5da3cc"
                      />
                    </g>
                  </g>
                </svg>

                <span>Cursos específicos</span>
              </div>
              <div class="fdn-features-tipos-content-list-item">
                <svg
                  id="video_online"
                  data-name="video online"
                  xmlns="http://www.w3.org/2000/svg"
                  width="105.97"
                  height="105.97"
                  viewBox="0 0 105.97 105.97"
                >
                  <path
                    id="Caminho_286"
                    data-name="Caminho 286"
                    d="M102.672,44.388H70.257a17.485,17.485,0,0,0,2.3-7.065h.094a5.3,5.3,0,0,0,5.3-5.3V30.259a5.3,5.3,0,0,0-3.225-4.875l2.708-2.708a1.766,1.766,0,0,0,.517-1.249V17.9A15.913,15.913,0,0,0,62.05,2H47.92a15.913,15.913,0,0,0-15.9,15.9v3.532a1.766,1.766,0,0,0,.517,1.248l2.708,2.708a5.3,5.3,0,0,0-3.225,4.875v1.766a5.3,5.3,0,0,0,5.3,5.3h.09a17.485,17.485,0,0,0,2.3,7.065H7.3a5.3,5.3,0,0,0-5.3,5.3v52.985a5.3,5.3,0,0,0,5.3,5.3h95.373a5.3,5.3,0,0,0,5.3-5.3V49.687a5.3,5.3,0,0,0-5.3-5.3Zm-47.687,5.3A14.145,14.145,0,0,1,40.856,35.557V22.592L51.582,18l17.533,4.777v12.78A14.145,14.145,0,0,1,54.985,49.687ZM74.413,32.025a1.766,1.766,0,0,1-1.766,1.766v-5.3a1.766,1.766,0,0,1,1.766,1.766ZM35.557,17.9A12.379,12.379,0,0,1,47.92,5.532H62.05A12.379,12.379,0,0,1,74.413,17.9v2.8l-1.766,1.766V21.428a1.766,1.766,0,0,0-1.3-1.7l-19.428-5.3a1.766,1.766,0,0,0-1.16.081L38.394,19.8a1.766,1.766,0,0,0-1.07,1.623v1.035L35.557,20.7Zm0,14.129V30.259a1.766,1.766,0,0,1,1.766-1.766v5.3A1.766,1.766,0,0,1,35.557,32.025Zm68.881,70.647a1.766,1.766,0,0,1-1.766,1.766H7.3a1.766,1.766,0,0,1-1.766-1.766V49.687A1.766,1.766,0,0,1,7.3,47.92h35.1a17.609,17.609,0,0,0,25.182,0h35.1a1.766,1.766,0,0,1,1.766,1.766Z"
                    transform="translate(-2 -2)"
                    fill="#5da3cc"
                  />
                  <rect
                    id="Retângulo_29"
                    data-name="Retângulo 29"
                    width="10"
                    height="3"
                    transform="translate(88.691 7.611)"
                    fill="#5da3cc"
                  />
                  <rect
                    id="Retângulo_30"
                    data-name="Retângulo 30"
                    width="10"
                    height="3"
                    transform="translate(88.691 14.611)"
                    fill="#5da3cc"
                  />
                  <path
                    id="Caminho_287"
                    data-name="Caminho 287"
                    d="M67.428,2H53.3A5.3,5.3,0,0,0,48,7.3V21.428a5.3,5.3,0,0,0,5.3,5.3h3.036L55.118,31.6A1.766,1.766,0,0,0,57.935,33.4l8.347-6.678h1.146a5.3,5.3,0,0,0,5.3-5.3V7.3a5.3,5.3,0,0,0-5.3-5.3Zm1.766,19.428a1.766,1.766,0,0,1-1.766,1.766H65.662a1.766,1.766,0,0,0-1.1.387l-4.746,3.8.5-1.989A1.766,1.766,0,0,0,58.6,23.194H53.3a1.766,1.766,0,0,1-1.766-1.766V7.3A1.766,1.766,0,0,1,53.3,5.532H67.428A1.766,1.766,0,0,1,69.194,7.3Z"
                    transform="translate(33.244 -2)"
                    fill="#5da3cc"
                  />
                  <rect
                    id="Retângulo_31"
                    data-name="Retângulo 31"
                    width="10"
                    height="3"
                    transform="translate(7.691 14.611)"
                    fill="#5da3cc"
                  />
                  <rect
                    id="Retângulo_32"
                    data-name="Retângulo 32"
                    width="10"
                    height="3"
                    transform="translate(7.691 21.611)"
                    fill="#5da3cc"
                  />
                  <path
                    id="Caminho_288"
                    data-name="Caminho 288"
                    d="M7.3,30.726H8.535l10.152,6.768a1.766,1.766,0,0,0,2.693-1.9l-1.222-4.871h1.27a5.3,5.3,0,0,0,5.3-5.3V11.3a5.3,5.3,0,0,0-5.3-5.3H7.3A5.3,5.3,0,0,0,2,11.3V25.428A5.3,5.3,0,0,0,7.3,30.726ZM5.532,11.3A1.766,1.766,0,0,1,7.3,9.532H21.428A1.766,1.766,0,0,1,23.194,11.3V25.428a1.766,1.766,0,0,1-1.766,1.766H17.9a1.766,1.766,0,0,0-1.713,2.194l.657,2.633-6.794-4.53a1.777,1.777,0,0,0-.98-.3H7.3a1.766,1.766,0,0,1-1.766-1.766Z"
                    transform="translate(-2 1.065)"
                    fill="#5da3cc"
                  />
                  <path
                    id="Caminho_289"
                    data-name="Caminho 289"
                    d="M47.217,50.017,29.556,41.186A1.766,1.766,0,0,0,27,42.767V60.428a1.766,1.766,0,0,0,2.556,1.59l17.662-8.831a1.766,1.766,0,0,0,0-3.161ZM30.532,57.571V45.624L42.479,51.6Z"
                    transform="translate(17.154 27.88)"
                    fill="#5da3cc"
                  />
                  <path
                    id="Caminho_290"
                    data-name="Caminho 290"
                    d="M8,62.791a1.766,1.766,0,0,0,1.766,1.766H31.713a19.325,19.325,0,0,0,1.607,3.974l-2.566,2.561a1.766,1.766,0,0,1-1.259.53H21.8a5.3,5.3,0,1,0,0,3.532h7.69a5.272,5.272,0,0,0,3.757-1.56l2.081-2.081a19.389,19.389,0,0,0,30.113,0l2.075,2.081a5.272,5.272,0,0,0,3.762,1.56h7.69a5.3,5.3,0,1,0,0-3.532h-7.69a1.766,1.766,0,0,1-1.259-.53l-2.566-2.561a19.325,19.325,0,0,0,1.607-3.974H91.01a1.766,1.766,0,0,0,1.766-1.766A31.791,31.791,0,0,0,60.985,31H39.791A31.828,31.828,0,0,0,8,62.791Zm8.831,12.363A1.766,1.766,0,1,1,18.6,73.388,1.766,1.766,0,0,1,16.831,75.154Zm67.115-3.532a1.766,1.766,0,1,1-1.766,1.766A1.766,1.766,0,0,1,83.945,71.622ZM50.388,75.154a15.9,15.9,0,1,1,15.9-15.9A15.9,15.9,0,0,1,50.388,75.154Zm10.6-40.622A28.271,28.271,0,0,1,89.191,61.025H69.726c.053-.583.09-1.169.09-1.766a19.428,19.428,0,0,0-38.856,0c0,.6.037,1.183.09,1.766H11.587a28.3,28.3,0,0,1,28.2-26.493Z"
                    transform="translate(2.597 20.219)"
                    fill="#5da3cc"
                  />
                </svg>

                <span>Professor virtual</span>
              </div>
              <div class="fdn-features-tipos-content-list-item">
                <svg
                  id="training"
                  xmlns="http://www.w3.org/2000/svg"
                  width="97.07"
                  height="97.071"
                  viewBox="0 0 97.07 97.071"
                >
                  <g
                    id="Grupo_31"
                    data-name="Grupo 31"
                    transform="translate(42.5 87.022)"
                  >
                    <g
                      id="Grupo_30"
                      data-name="Grupo 30"
                    >
                      <path
                        id="Caminho_292"
                        data-name="Caminho 292"
                        d="M234.436,459h-8.373a1.9,1.9,0,1,0,0,3.792h8.373a1.9,1.9,0,1,0,0-3.792Z"
                        transform="translate(-224.167 -459)"
                        fill="#ffa24f"
                      />
                    </g>
                  </g>
                  <g
                    id="Grupo_33"
                    data-name="Grupo 33"
                    transform="translate(64.84 13.725)"
                  >
                    <g
                      id="Grupo_32"
                      data-name="Grupo 32"
                    >
                      <path
                        id="Caminho_293"
                        data-name="Caminho 293"
                        d="M345.237,72.947a1.895,1.895,0,1,0,.556,1.34A1.91,1.91,0,0,0,345.237,72.947Z"
                        transform="translate(-342.001 -72.391)"
                        fill="#ffa24f"
                      />
                    </g>
                  </g>
                  <g
                    id="Grupo_35"
                    data-name="Grupo 35"
                    transform="translate(0 0)"
                  >
                    <g
                      id="Grupo_34"
                      data-name="Grupo 34"
                    >
                      <path
                        id="Caminho_294"
                        data-name="Caminho 294"
                        d="M95.175,29.194a1.9,1.9,0,0,0,1.9-1.9V15.621a1.894,1.894,0,0,0-1.044-1.692L96,13.913,67.56.188a1.9,1.9,0,0,0-1.648,0L37.473,13.913a1.9,1.9,0,0,0,0,3.415l11.084,5.349V34.4a1.892,1.892,0,0,0,.019.263H48.05a6.318,6.318,0,0,0,0,12.636h.612a18.192,18.192,0,0,0,7.73,12.99H38.73a6.838,6.838,0,0,0-3.087.732V33.045a3.091,3.091,0,0,1,3.087-3.087h4.9a1.9,1.9,0,1,0,0-3.792h-4.9a6.858,6.858,0,0,0-4.983,2.144,6.858,6.858,0,0,0-4.983-2.144H1.9a1.9,1.9,0,0,0-1.9,1.9V69.585a1.9,1.9,0,0,0,1.9,1.9H4.17v9.285H1.9a1.9,1.9,0,0,0-1.9,1.9v3.413a11.009,11.009,0,0,0,11,11H86.074a11.009,11.009,0,0,0,11-11V82.661a1.9,1.9,0,0,0-1.9-1.9H92.9V52.9a1.9,1.9,0,1,0-3.792,0v27.87H7.962V71.48H65.6a1.9,1.9,0,0,0,1.9-1.9V63.514A18.207,18.207,0,0,0,84.81,47.3h.612a6.318,6.318,0,0,0,0-12.636H84.9a1.89,1.89,0,0,0,.019-.263V22.677l8.364-4.037V27.3A1.9,1.9,0,0,0,95.175,29.194ZM48.557,43.511h-.508a2.526,2.526,0,0,1,0-5.052h.508ZM3.792,29.958H28.764a3.091,3.091,0,0,1,3.087,3.087v27.98a6.836,6.836,0,0,0-3.087-.732H3.792Zm89.487,54.6v1.517a7.213,7.213,0,0,1-7.2,7.2H11a7.213,7.213,0,0,1-7.2-7.2V84.557ZM3.792,67.689v-3.6H28.764a3.091,3.091,0,0,1,3.087,3.087,1.9,1.9,0,0,0,.073.518Zm81.123-29.23h.508a2.526,2.526,0,0,1,0,5.052h-.508ZM63.7,67.689H35.57a1.889,1.889,0,0,0,.073-.518,3.091,3.091,0,0,1,3.087-3.087H63.7Zm17.42-22.337a14.387,14.387,0,1,1-28.774,0V37.309L65.972,43.3a1.9,1.9,0,0,0,1.527,0l13.623-5.994Zm0-12.185L66.736,39.5l-14.387-6.33V24.508l13.563,6.546a1.9,1.9,0,0,0,1.648,0l13.563-6.546v8.659Zm-9.055-15.65H86.884L66.736,27.241,42.659,15.621,66.736,4l20.148,9.724H72.068a1.9,1.9,0,1,0,0,3.792Z"
                        transform="translate(0 0)"
                        fill="#ffa24f"
                      />
                    </g>
                  </g>
                  <g
                    id="Grupo_37"
                    data-name="Grupo 37"
                    transform="translate(93.279 33.859)"
                  >
                    <g
                      id="Grupo_36"
                      data-name="Grupo 36"
                    >
                      <path
                        id="Caminho_295"
                        data-name="Caminho 295"
                        d="M495.236,179.145a1.895,1.895,0,1,0,.556,1.34A1.911,1.911,0,0,0,495.236,179.145Z"
                        transform="translate(-492 -178.59)"
                        fill="#ffa24f"
                      />
                    </g>
                  </g>
                  <g
                    id="Grupo_39"
                    data-name="Grupo 39"
                    transform="translate(22.146 47.777)"
                  >
                    <g
                      id="Grupo_38"
                      data-name="Grupo 38"
                    >
                      <path
                        id="Caminho_296"
                        data-name="Caminho 296"
                        d="M120.046,252.555a1.895,1.895,0,1,0,.555,1.34A1.914,1.914,0,0,0,120.046,252.555Z"
                        transform="translate(-116.81 -252)"
                        fill="#ffa24f"
                      />
                    </g>
                  </g>
                  <g
                    id="Grupo_41"
                    data-name="Grupo 41"
                    transform="translate(8.911 47.78)"
                  >
                    <g
                      id="Grupo_40"
                      data-name="Grupo 40"
                    >
                      <path
                        id="Caminho_297"
                        data-name="Caminho 297"
                        d="M54.963,252.017H48.9a1.9,1.9,0,0,0,0,3.792h6.067a1.9,1.9,0,1,0,0-3.792Z"
                        transform="translate(-47 -252.017)"
                        fill="#ffa24f"
                      />
                    </g>
                  </g>
                  <g
                    id="Grupo_43"
                    data-name="Grupo 43"
                    transform="translate(8.911 38.679)"
                  >
                    <g
                      id="Grupo_42"
                      data-name="Grupo 42"
                    >
                      <path
                        id="Caminho_298"
                        data-name="Caminho 298"
                        d="M62.131,204.012H48.9a1.9,1.9,0,1,0,0,3.792H62.131a1.9,1.9,0,1,0,0-3.792Z"
                        transform="translate(-47 -204.012)"
                        fill="#ffa24f"
                      />
                    </g>
                  </g>
                </svg>

                <span>Aula Particular</span>
              </div>
              <div class="fdn-features-tipos-content-list-item">
                <svg
                  id="implement"
                  xmlns="http://www.w3.org/2000/svg"
                  width="121.142"
                  height="121.142"
                  viewBox="0 0 121.142 121.142"
                >
                  <path
                    id="Caminho_359"
                    data-name="Caminho 359"
                    d="M10.1,186.933h90.857a2.019,2.019,0,0,0,2.019-2.019V132.419H98.933v50.476H10.1a6.057,6.057,0,1,1,0-12.114h4.038a2.019,2.019,0,0,0,2.019-2.019V108.19h32.3v-4.038h-32.3V90.019A2.019,2.019,0,0,0,14.133,88H10.1A10.107,10.107,0,0,0,0,98.1v78.742A10.107,10.107,0,0,0,10.1,186.933ZM4.038,106.171V98.1A6.057,6.057,0,0,1,10.1,92.038h2.019v74.7H10.1a10.055,10.055,0,0,0-6.057,2.019Zm0,0"
                    transform="translate(0 -65.791)"
                    fill="#ffb97a"
                  />
                  <path
                    id="Caminho_360"
                    data-name="Caminho 360"
                    d="M106.247,192H82.019A2.019,2.019,0,0,0,80,194.019v16.152a2.019,2.019,0,0,0,2.019,2.019h24.228a2.019,2.019,0,0,0,2.019-2.019v-6.057h8.076v-4.038h-8.076v-6.057A2.019,2.019,0,0,0,106.247,192Zm-2.019,16.152H84.038V196.038h20.19Zm0,0"
                    transform="translate(-59.81 -143.543)"
                    fill="#ffb97a"
                  />
                  <path
                    id="Caminho_361"
                    data-name="Caminho 361"
                    d="M126.438,320.3h24.228a2.019,2.019,0,0,0,2.019-2.019V302.133a2.019,2.019,0,0,0-2.019-2.019h-10.1V288h-4.038v12.114h-10.1a2.019,2.019,0,0,0-2.019,2.019v6.057H108.266v-6.057a2.019,2.019,0,0,0-2.019-2.019H82.019A2.019,2.019,0,0,0,80,302.133v16.152a2.019,2.019,0,0,0,2.019,2.019h24.228a2.019,2.019,0,0,0,2.019-2.019v-6.057h16.152v6.057A2.019,2.019,0,0,0,126.438,320.3Zm2.019-16.152h20.19v12.114h-20.19Zm-24.228,12.114H84.038V304.152h20.19Zm0,0"
                    transform="translate(-59.81 -215.315)"
                    fill="#ffb97a"
                  />
                  <path
                    id="Caminho_362"
                    data-name="Caminho 362"
                    d="M290.171,64a18.171,18.171,0,1,0,18.171,18.171A18.171,18.171,0,0,0,290.171,64Zm0,32.3A14.133,14.133,0,1,1,304.3,82.171,14.133,14.133,0,0,1,290.171,96.3Zm0,0"
                    transform="translate(-203.353 -47.848)"
                    fill="#ffb97a"
                  />
                  <path
                    id="Caminho_363"
                    data-name="Caminho 363"
                    d="M274.628,42.4a2.019,2.019,0,0,0,2.019-2.019V28.266a2.019,2.019,0,0,0-2.019-2.019h-7.355a26.753,26.753,0,0,0-1.6-3.857l5.209-5.191a2.018,2.018,0,0,0,0-2.859L262.312,5.76a2.016,2.016,0,0,0-2.855,0l-5.2,5.209a26.86,26.86,0,0,0-3.857-1.6V2.019A2.019,2.019,0,0,0,248.381,0H236.266a2.019,2.019,0,0,0-2.019,2.019V9.374a26.857,26.857,0,0,0-3.857,1.6L225.2,5.762a2.016,2.016,0,0,0-2.855,0l-8.583,8.575a2.018,2.018,0,0,0,0,2.859l5.209,5.191a26.693,26.693,0,0,0-1.6,3.863h-7.355A2.019,2.019,0,0,0,208,28.266V40.381a2.019,2.019,0,0,0,2.019,2.019h7.355a26.743,26.743,0,0,0,1.6,3.857l-5.209,5.191a2.018,2.018,0,0,0,0,2.861l8.573,8.559a2.02,2.02,0,0,0,2.855,0l5.192-5.192a27,27,0,0,0,3.865,1.6v7.355a2.019,2.019,0,0,0,2.019,2.019h12.114a2.019,2.019,0,0,0,2.019-2.019V59.273a26.881,26.881,0,0,0,3.859-1.6l5.192,5.192a2.02,2.02,0,0,0,2.853,0l8.581-8.561a2.018,2.018,0,0,0,0-2.861l-5.209-5.191a26.729,26.729,0,0,0,1.6-3.855Zm-10.814-2.534a23.093,23.093,0,0,1-2.379,5.736,2.019,2.019,0,0,0,.319,2.447l4.845,4.83-5.72,5.708-4.828-4.834a2.02,2.02,0,0,0-2.445-.317,23,23,0,0,1-5.736,2.377,2.019,2.019,0,0,0-1.51,1.952v6.845h-8.076V57.764a2.019,2.019,0,0,0-1.5-1.952,23,23,0,0,1-5.736-2.377,2.02,2.02,0,0,0-2.445.317l-4.832,4.832-5.72-5.706,4.845-4.83a2.019,2.019,0,0,0,.319-2.447,23.091,23.091,0,0,1-2.379-5.736,2.019,2.019,0,0,0-1.952-1.5h-6.845V30.285h6.845a2.019,2.019,0,0,0,1.95-1.5,23.091,23.091,0,0,1,2.379-5.736,2.019,2.019,0,0,0-.319-2.447l-4.845-4.832,5.722-5.722L228.6,14.89a2.019,2.019,0,0,0,2.447.319,23,23,0,0,1,5.736-2.377,2.02,2.02,0,0,0,1.5-1.95V4.038h8.076v6.845a2.019,2.019,0,0,0,1.5,1.952,23,23,0,0,1,5.736,2.377,2.019,2.019,0,0,0,2.451-.319l4.832-4.845,5.722,5.722L261.761,20.6a2.017,2.017,0,0,0-.318,2.447,23.125,23.125,0,0,1,2.378,5.736,2.018,2.018,0,0,0,1.944,1.5h6.845v8.076h-6.845a2.019,2.019,0,0,0-1.95,1.5Zm0,0"
                    transform="translate(-155.505 0)"
                    fill="#ffb97a"
                  />
                  <path
                    id="Caminho_364"
                    data-name="Caminho 364"
                    d="M320.076,104a8.076,8.076,0,1,0,8.076,8.076A8.077,8.077,0,0,0,320.076,104Zm0,12.114a4.038,4.038,0,1,1,4.038-4.038A4.038,4.038,0,0,1,320.076,116.114Zm0,0"
                    transform="translate(-233.258 -77.753)"
                    fill="#ffb97a"
                  />
                </svg>

                <span>Vendas</span>
              </div>
              <div class="fdn-features-tipos-content-list-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="63.214"
                  height="59"
                  viewBox="0 0 63.214 59"
                >
                  <g
                    id="surface1"
                    transform="translate(0 -0.25)"
                  >
                    <path
                      id="Caminho_325"
                      data-name="Caminho 325"
                      d="M4.214,50.821H6.321V48.714H4.214a2.107,2.107,0,0,1-2.107-2.107V4.464A2.107,2.107,0,0,1,4.214,2.357H50.571a2.107,2.107,0,0,1,2.107,2.107V6.571h2.107V4.464A4.214,4.214,0,0,0,50.571.25H4.214A4.214,4.214,0,0,0,0,4.464V46.607A4.214,4.214,0,0,0,4.214,50.821Zm0,0"
                      fill="#5da3cc"
                    />
                    <path
                      id="Caminho_326"
                      data-name="Caminho 326"
                      d="M62.5,62.75h2.107v2.107H62.5Zm0,0"
                      transform="translate(-58.286 -58.286)"
                      fill="#5da3cc"
                    />
                    <path
                      id="Caminho_327"
                      data-name="Caminho 327"
                      d="M125,62.75h2.107v2.107H125Zm0,0"
                      transform="translate(-116.571 -58.286)"
                      fill="#5da3cc"
                    />
                    <path
                      id="Caminho_328"
                      data-name="Caminho 328"
                      d="M187.5,62.75h2.107v2.107H187.5Zm0,0"
                      transform="translate(-174.857 -58.286)"
                      fill="#5da3cc"
                    />
                    <path
                      id="Caminho_329"
                      data-name="Caminho 329"
                      d="M125,171.607a4.214,4.214,0,0,0,4.214,4.214h46.357a4.214,4.214,0,0,0,4.214-4.214V129.464a4.214,4.214,0,0,0-4.214-4.214H129.214A4.214,4.214,0,0,0,125,129.464Zm2.107-42.143a2.107,2.107,0,0,1,2.107-2.107h46.357a2.107,2.107,0,0,1,2.107,2.107v42.143a2.107,2.107,0,0,1-2.107,2.107H129.214a2.107,2.107,0,0,1-2.107-2.107Zm0,0"
                      transform="translate(-116.571 -116.571)"
                      fill="#5da3cc"
                    />
                    <path
                      id="Caminho_330"
                      data-name="Caminho 330"
                      d="M187.5,187.75h2.107v2.107H187.5Zm0,0"
                      transform="translate(-174.857 -174.857)"
                      fill="#5da3cc"
                    />
                    <path
                      id="Caminho_331"
                      data-name="Caminho 331"
                      d="M250,187.75h2.107v2.107H250Zm0,0"
                      transform="translate(-233.143 -174.857)"
                      fill="#5da3cc"
                    />
                    <path
                      id="Caminho_332"
                      data-name="Caminho 332"
                      d="M312.5,187.75h2.107v2.107H312.5Zm0,0"
                      transform="translate(-291.429 -174.857)"
                      fill="#5da3cc"
                    />
                    <path
                      id="Caminho_333"
                      data-name="Caminho 333"
                      d="M187.5,250.25h46.357v2.107H187.5Zm0,0"
                      transform="translate(-174.857 -233.143)"
                      fill="#5da3cc"
                    />
                    <path
                      id="Caminho_334"
                      data-name="Caminho 334"
                      d="M187.5,328.375h8.429v2.107H187.5Zm0,0"
                      transform="translate(-174.857 -306)"
                      fill="#5da3cc"
                    />
                    <path
                      id="Caminho_335"
                      data-name="Caminho 335"
                      d="M187.5,390.875h6.321v2.107H187.5Zm0,0"
                      transform="translate(-174.857 -364.286)"
                      fill="#5da3cc"
                    />
                    <path
                      id="Caminho_336"
                      data-name="Caminho 336"
                      d="M429.25,453.375a7.375,7.375,0,1,0,7.375,7.375A7.375,7.375,0,0,0,429.25,453.375Zm0,12.643a5.268,5.268,0,1,1,5.268-5.268A5.268,5.268,0,0,1,429.25,466.018Zm0,0"
                      transform="translate(-393.429 -422.571)"
                      fill="#5da3cc"
                    />
                    <path
                      id="Caminho_337"
                      data-name="Caminho 337"
                      d="M314.964,326.446a1.053,1.053,0,0,0-1.054-1.054h-2.785a13.4,13.4,0,0,0-.833-2.012l1.969-1.968a1.055,1.055,0,0,0,0-1.49l-4.47-4.47a1.055,1.055,0,0,0-1.49,0l-1.968,1.968a13.63,13.63,0,0,0-2.012-.832V313.8a1.053,1.053,0,0,0-1.054-1.054h-6.321a1.053,1.053,0,0,0-1.054,1.054v2.785a13.627,13.627,0,0,0-2.012.832l-1.968-1.968a1.055,1.055,0,0,0-1.49,0l-4.47,4.47a1.055,1.055,0,0,0,0,1.49l1.968,1.968c-.088.173-.173.346-.253.52a13.629,13.629,0,0,0-.579,1.492H282.3a1.053,1.053,0,0,0-1.054,1.054v6.321a1.053,1.053,0,0,0,1.054,1.054h2.785a13.4,13.4,0,0,0,.833,2.012l-1.969,1.968a1.055,1.055,0,0,0,0,1.49l4.47,4.47a1.055,1.055,0,0,0,1.49,0l1.968-1.968a13.627,13.627,0,0,0,2.012.832v2.785a1.053,1.053,0,0,0,1.054,1.054h6.321a1.053,1.053,0,0,0,1.054-1.054v-2.785a13.63,13.63,0,0,0,2.012-.832l1.968,1.968a1.055,1.055,0,0,0,1.49,0l4.47-4.47a1.055,1.055,0,0,0,0-1.49l-1.969-1.968a13.4,13.4,0,0,0,.833-2.012h2.785a1.053,1.053,0,0,0,1.054-1.054Zm-2.107,5.268h-2.522a1.054,1.054,0,0,0-1.02.79,11.522,11.522,0,0,1-1.233,2.979,1.054,1.054,0,0,0,.162,1.28l1.781,1.782-2.98,2.981-1.782-1.783a1.054,1.054,0,0,0-1.281-.162A11.51,11.51,0,0,1,301,340.815a1.054,1.054,0,0,0-.79,1.02v2.522H296v-2.522a1.054,1.054,0,0,0-.79-1.02,11.509,11.509,0,0,1-2.979-1.233,1.054,1.054,0,0,0-1.281.162l-1.782,1.783-2.98-2.981,1.781-1.782a1.054,1.054,0,0,0,.162-1.28A11.519,11.519,0,0,1,286.9,332.5a1.054,1.054,0,0,0-1.02-.79h-2.522V327.5h2.522a1.054,1.054,0,0,0,1.02-.79,11.445,11.445,0,0,1,.685-1.931,11.629,11.629,0,0,1,.548-1.054,1.054,1.054,0,0,0-.162-1.279l-1.781-1.782,2.98-2.981,1.782,1.783a1.053,1.053,0,0,0,1.281.161,11.523,11.523,0,0,1,2.979-1.233,1.053,1.053,0,0,0,.79-1.019v-2.518h4.214v2.522a1.054,1.054,0,0,0,.79,1.02,11.508,11.508,0,0,1,2.979,1.233,1.054,1.054,0,0,0,1.281-.162l1.782-1.783,2.98,2.981-1.781,1.782a1.054,1.054,0,0,0-.162,1.28,11.521,11.521,0,0,1,1.233,2.979,1.054,1.054,0,0,0,1.02.79h2.522Zm0,0"
                      transform="translate(-262.286 -291.429)"
                      fill="#5da3cc"
                    />
                    <path
                      id="Caminho_338"
                      data-name="Caminho 338"
                      d="M781.25,781.5h6.321v2.107H781.25Zm0,0"
                      transform="translate(-728.571 -728.571)"
                      fill="#5da3cc"
                    />
                  </g>
                </svg>

                <span>Personalização</span>
              </div>
            </div>
          </div>
          <div class="fdn-features-tipos-footer">
            <div class="fdn-features-tipos-footer-actions">
              <a
                href="https://api.whatsapp.com/send?phone=+5511994478241&text=Ol%C3%A1%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto"
                target="_blank"
                class="btn btn-default btn-lg"
              >Fale com um consultor</a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- fd-linha-mestre -->
    <section class="fd-linha-mestre">
      <div class="container">
        <div class="fd-linha-mestre-content">
          <div
            class="row align-items-center"
            style="position: relative;"
          >
            <div id="parceria">
              <span>
                Em parceria com
                <img v-lazy="require('@/assets/images/concurseiro-logo.png')">
              </span>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-4">
              <h2 id="top">
                <span>
                  <img v-lazy="require('@/assets/images/linha-mestre-icon.png')">
                </span>
                <span id="linha">Linha</span>
                <span id="mestre">Mestre</span>
              </h2>
              <h2 id="bottom">
                <span id="number">+200</span>
                <span id="professores">Professores</span>
              </h2>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-8">
              <div class="fd-linha-mestre-content-text">
                <p>
                  +240 Profissionais de educação homologados na plataforma, professores, mestres, doutores e phds de todo Brasil a disposição da sua equipe.
                </p>

                <p>O Poder da industria 4.0 agora para mercado da educação, utilize o modelo híbrido para o ensino e o nosso conceito para EAD Humanizado.</p>

                <p>Interações com professores da sua empresa ou da nossa plataforma direto ao seu aluno, cliente ou profissional de sáude.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="fd-linha-mestre-border" />
    </section>
    <!-- /fd-linha-mestre -->

    <section class="fdn-empresas-destaque d-none">
      <div class="container">
        <div class="fdn-empresas-destaque-header">
          <h2>Empresas que confiam</h2>
        </div>
        <div class="fdn-empresas-destaque-content">
          <div class="fdn-empresas-destaque-content-item column-reverse-sm">
            <div class="fdn-empresas-destaque-content-item-video">
              <!-- <vue-plyr>
                <div
                  data-plyr-provider="vimeo"
                  data-plyr-embed-id="368390369"
                />
              </vue-plyr> -->
            </div>
            <div class="fdn-empresas-destaque-content-item-text">
              <img
                v-lazy="require('@/assets/images/logo-federaminas.png')"
                alt="Federaminas"
              >
              <p>
                Plataforma EAD para Federações e Associações.
                <br>Conheça a plataforma da Federaminas.
              </p>
              <div class="fdn-empresas-destaque-content-item-text-action">
                <a
                  href="http://federaminas.fastead.com/"
                  target="_blank"
                  class="btn btn-primary ml-auto"
                >Acessar plataforma</a>
              </div>
            </div>
          </div>
          <div class="fdn-empresas-destaque-content-item">
            <div class="fdn-empresas-destaque-content-item-text">
              <img
                v-lazy="require('@/assets/images/logo_fecomercio.png')"
                alt="Fecomércio MG"
              >
            </div>
            <div
              class="fdn-empresas-destaque-content-item-video"
              :style="{
                backgroundImage: 'url(' + require('@/assets/images/prefeitura.png') + ')',
              }"
            />
          </div>
          <div class="fdn-empresas-destaque-content-cta">
            <a
              href="https://api.whatsapp.com/send?phone=+5511994478241&text=Ol%C3%A1%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto"
              target="_blank"
              class="btn btn-primary btn-lg"
            >Fale com um consultor</a>
            <img
              v-lazy="require('@/assets/images/bg-effect.png')"
              class="fdn-empresas-destaque-content-cta-effect"
            >
          </div>
        </div>
      </div>
    </section>

    <section class="fd-contacts mt-0">
      <div class="container">
        <div class="row justify-content-around">
          <div class="col-sm-12 col-md-12 col-lg-5">
            <div id="fd-contacts-fale-conosco">
              <h2>Fale com um consultor</h2>
              <img
                id="fd-contacts-header-form-effect"
                v-lazy="require('@/assets/images/form-effect-01.png')"
                class="img-fluid"
              >
              <p>Fale com nossos consultores por um dos atendimentos rápidos abaixo:</p>
              <div class="fd-contacts-consultor">
                <div class="fd-contacts-consultor-avatar">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="64"
                    height="64"
                    viewBox="0 0 64 64"
                  >
                    <path
                      id="messaging"
                      d="M67.8,4.75H13.256a.945.945,0,0,0-.945.945V27.7H5.7a.945.945,0,0,0-.945.945V58.807a.945.945,0,0,0,.945.945H24.9l14.163,8.854a.946.946,0,0,0,1.436-.943l-1.2-7.911h15.87a.945.945,0,0,0,.945-.945V39.888H67.8a.946.946,0,0,0,.945-.945V5.7A.945.945,0,0,0,67.8,4.75ZM54.233,57.862H38.208a.945.945,0,0,0-.934,1.087l1.054,6.966-12.649-7.91a.951.951,0,0,0-.5-.143H6.64V29.594h5.671v9.349a.946.946,0,0,0,.945.945H30.867l-1.337,8.83a.946.946,0,0,0,1.436.943L46.6,39.888h7.631ZM66.86,38H46.331a.945.945,0,0,0-.5.143L31.707,46.969,32.9,39.084A.946.946,0,0,0,31.966,38H14.2V6.64H66.86Z"
                      transform="translate(-4.75 -4.75)"
                      fill="#fff"
                    />
                  </svg>
                </div>
                <div class="fd-contacts-consultor-body">
                  <h3>Posso Ajudar?</h3>
                  <p>Fale conosco atraves do chat!</p>
                </div>
              </div>
              <div class="fd-contacts-whatsapp">
                <ul>
                  <li>
                    <svg
                      id="whatsapp"
                      xmlns="http://www.w3.org/2000/svg"
                      width="63.036"
                      height="63.037"
                      viewBox="0 0 63.036 63.037"
                    >
                      <path
                        id="Caminho_213"
                        data-name="Caminho 213"
                        d="M31.526,0H31.51A31.5,31.5,0,0,0,6,49.992L2.072,61.7l12.115-3.873A31.513,31.513,0,1,0,31.526,0Z"
                        fill="#4caf50"
                      />
                      <path
                        id="Caminho_214"
                        data-name="Caminho 214"
                        d="M143.924,147.18c-.76,2.147-3.778,3.928-6.185,4.448-1.647.351-3.8.63-11.039-2.372-9.262-3.837-15.227-13.249-15.692-13.86-.445-.611-3.743-4.984-3.743-9.507a10.062,10.062,0,0,1,3.223-7.671,4.579,4.579,0,0,1,3.223-1.131c.39,0,.741.02,1.056.035.926.039,1.391.095,2,1.556.76,1.832,2.612,6.355,2.833,6.82a1.876,1.876,0,0,1,.134,1.706,5.447,5.447,0,0,1-1.02,1.446c-.465.536-.906.946-1.371,1.521-.425.5-.906,1.036-.37,1.962a27.976,27.976,0,0,0,5.114,6.355,23.172,23.172,0,0,0,7.391,4.558,1.993,1.993,0,0,0,2.222-.351,38.141,38.141,0,0,0,2.462-3.262,1.759,1.759,0,0,1,2.261-.686c.851.3,5.354,2.521,6.28,2.982s1.537.686,1.761,1.076A7.849,7.849,0,0,1,143.924,147.18Z"
                        transform="translate(-94.058 -102.672)"
                        fill="#fafafa"
                      />
                    </svg>
                  </li>
                  <li>{{ fastTelefoneContato }}</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-5 rd-contact" />
        </div>
      </div>
    </section>
    <!-- fd-footer -->
  </div>
</template>

<script>
//import imageSiteFeaturesPlataforma from "@/assets/images/features-plataforma.png";
//import imageSiteBgEffect from "@/assets/images/bg-effect.png";
//import ImageSiteLogoFasteadBusiness from "@/assets/images/logo_fastead_business.png";
//import ImageSiteFeaturesBusiness from "@/assets/images/features-business.png";
//import ImageSiteConcurseiroLogo from "@/assets/images/concurseiro-logo.png";
//import ImageSiteLinhaMestreIcon from "@/assets/images/linha-mestre-icon.png";
//import ImageSiteLogoFederaminas from "@/assets/images/logo-federaminas.png";
//import ImageSiteLogoFecomercio from "@/assets/images/logo_fecomercio.png";
//import ImageSitePrefeitura from "@/assets/images/prefeitura.png";
//import imageSiteBgEffect from "@/assets/images/bg-effect.png";
//import ImageSiteFormEffect01 from "@/assets/images/form-effect-01.png";
//import ImageSiteLogoFasteadEscolas from "@/assets/images/logo_fastead_escolas.png";
//import ImageSiteFeaturesEscola from "@/assets/images/features-escola.png";
import { settings } from "@/config";
//import VuePlyr from "vue-plyr";

export default {
  name: "HomeExternoPrincipal",
  components: {
    //VuePlyr,
  },
  data: function() {
    return {
      //imageSiteFeaturesPlataforma,
      //imageSiteBgEffect,
      //ImageSiteLogoFasteadBusiness,
      //ImageSiteFeaturesBusiness,
      //ImageSiteConcurseiroLogo,
      //ImageSiteLinhaMestreIcon,
      //ImageSiteLogoFederaminas,
      //ImageSiteLogoFecomercio,
      //ImageSitePrefeitura,
      //imageSiteBgEffect,
      //ImageSiteFormEffect01,
      //ImageSiteLogoFasteadEscolas,
      //ImageSiteFeaturesEscola,
    };
  },
  computed: {
    fastTelefoneContato() {
      return settings.fastTelefoneContato.slice(0, 3) + " (" + settings.fastTelefoneContato.slice(3, 5) + ")" + " " + settings.fastTelefoneContato.slice(5);
    },
  },
  mounted() {},
};
</script>

<style></style>
